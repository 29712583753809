import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RescheduleAppointmentService } from '../_services/reschedule-appointment.service';
@Component({
  selector: 'app-cancel-appointment',
  templateUrl: './cancel-appointment.component.html',
  styleUrls: ['./cancel-appointment.component.css']
})
export class CancelAppointmentComponent implements OnInit {

  reschedule_doc: string;
  appointment_details: any;
  appt_status: string;
  oldDate: any;
  appointmentWith: string;
  timeslot: string;
  isCancel: boolean = false;
  showMessage: string;
  isReady: boolean = false;
  currentDate: any;
  canceling: boolean;
  constructor(
    public rescheduleService: RescheduleAppointmentService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit() {
    this.route.url.subscribe(url => {
      this.reschedule_doc = url[1].path;
      console.log(this.reschedule_doc);
      this.rescheduleService.fetchAppointmentData(this.reschedule_doc)
        .then(appointment_details => {
          this.appointment_details = appointment_details;
          this.appt_status = this.appointment_details.status;
          //this.timeslot = this.appointment_details.slot;
          if (this.appointment_details.provider_name !== undefined) {
            this.rescheduleService.providersName = this.appointment_details.provider_name;
          }
          this.timeslot = this.rescheduleService.getOldAppointmentTime();
          console.log(this.timeslot);
          //this.appointmentWith = this.rescheduleService.acc_name;
          //console.log(this.appointmentWith);
          let apptDate = this.rescheduleService.getOldAppointmentDate();
          this.oldDate = apptDate;
          this.currentDate = new Date();
          this.isReady = true;
        });
    })
  }

  cancelAppointment() {
    this.canceling = true
    this.rescheduleService.statusToCancel(this.appointment_details)
      .then(
        success => {
          this.isCancel = true;
          let formData = this.rescheduleService.getContactDetails();
          this.rescheduleService.cancelEmailToOwner(formData);
          console.log(formData);
          this.rescheduleService.cancelEmailToUser(formData.email);
          // this.rescheduleService.sendSmsToUser("cancelled");
        }
      );


  }

}
