<div *ngIf="showSummryForMobile==true; " class="mobile-view-summary">
  <app-appointment-details [selectedServices]="services_chosen"></app-appointment-details>
</div>
<div [hidden]="showSummryForMobile" id="contact-details-screen">
  <div class="center">
    <div>
      <div id="deskView">
        <div class="row">
          <div class="row" id="subHeader">
            <div class="col-xs-4">
              <span style="cursor: pointer;margin-top: -15px;" class="material-icons arrow" (click)="goBack()">
                keyboard_arrow_left
              </span>
              <a (click)="goBack()">Select Another Date and Time</a>
            </div>
            <div style="text-align: center;" class="col-xs-4">
              <b>Fill Contact Details </b>
            </div>
            <div class="col-xs-4">

            </div>
          </div>
        </div>

      </div>
      <div id="mobileView">
        <div class="col-xs-12" id="subHeader">
          <div class="col-xs-12" style="font-size:large;text-align: center;">
            <b>Fill Contact Details </b>
          </div>
        </div>
      </div>
      <div>
        <!-- <app-strip-clender (onDateSelect)="onDateChange($event)"></app-strip-clender> -->
        <div *ngIf="showForm" class="container-fluid">
          <div class="spinner" *ngIf="loading">
            <div class="spinner">
              <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
              <p>Loading...</p>
            </div>
          </div>
          <div div *ngIf="!loading">
            <div *ngIf="timeslots.slots.length > 0">
              <p class="highlight" style="text-align: center;">What time works for you?</p>

              <div class="appointment_slot" [class.selected]="selectedSlot === timeslot"
                *ngFor="let timeslot of timeslots.slots" (click)="setTimeslot(timeslot)">
                <!-- <p>{{ timeslot.start }} - {{ timeslot.end }}</p> -->
                <p>
                  {{ appointmentService.convertToLocalTime(timeslot.start) }} -
                  {{ appointmentService.convertToLocalTime(timeslot.end) }}
                </p>
              </div>
              <small><em>*The above timings are in
                  {{ appointmentService.getLocalTimezoneAbbr() }}</em></small>
            </div>
            <div *ngIf="timeslots.slots.length == 0">
              <!-- <i class="material-icons status-symbol">cancel</i> -->
              <p class="highlight" style="text-align: center;">
                Sorry, all slots for this date have been booked.
              </p>
            </div>
          </div>
        </div>
        <div [hidden]="showForm">
          <div class="container-fluid">
            <form #formData="ngForm" (ngSubmit)="confirmAppointment(formData)" name="myForm">
              <div class="row">

                <div class="col-md-9" id="contactDeatailsForm">
                  <div class="row">
                    <div class="col-md-6" style=" padding-left: 3em; " *ngIf="!calendar_configurations.hideNewPatient || 
                      calendar_configurations.displayVisitType || calendar_configurations.displayEmergency">
                      <div *ngIf="calendar_configurations.displayEmergency" class="row" style="margin-top: 15px;">
                        <div class="highlight">Is this an Emergency? <span style="color: red;">*</span></div>
                        <div>
                          <mat-radio-group [class.has-error]="patient.invalid && patient.touched"
                            aria-labelledby="example-radio-group-label" class="example-radio-group" #patient="ngModel"
                            name="isEmergency" [(ngModel)]="isEmergency" required>
                            <mat-radio-button class="example-radio-button" *ngFor="let selectedOption of emergency"
                              [value]="selectedOption">
                              {{ selectedOption }}
                            </mat-radio-button>
                          </mat-radio-group>
                          <div *ngIf="patient.invalid && patient.touched" class="alert alert-danger">
                            Patient field is required
                          </div>
                        </div>
                      </div>


                      <div class="row" style="margin-top: 15px;" *ngIf="calendar_configurations.displayVisitType">
                        <div class="highlight">
                          Visit Type? <span style="color: red;">*</span>
                        </div>
                        <div>
                          <mat-radio-group [class.has-error]="apointmentType.invalid && apointmentType.touched"
                            aria-labelledby="example-radio-group-label" class="example-radio-group"
                            #apointmentType="ngModel" name="apointmentType" [(ngModel)]="type" required>
                            <mat-radio-button class="example-radio-button"
                              *ngFor="let selectedOption of apointmentTypeOptions" [value]="selectedOption">
                              {{ selectedOption }}
                            </mat-radio-button>
                          </mat-radio-group>
                          <div *ngIf="apointmentType.invalid && apointmentType.touched" class="alert alert-danger">
                            This field is required
                          </div>
                        </div>
                      </div>

                      <div class="row" style="margin-top: 15px;" *ngIf="!calendar_configurations.hideNewPatient">
                        <div class="highlight">Are you a new patient? <span style="color: red;">*</span></div>
                        <div>
                          <mat-radio-group [class.has-error]="patient.invalid && patient.touched"
                            aria-labelledby="example-radio-group-label" class="example-radio-group" #patient="ngModel"
                            name="patient" [(ngModel)]="patientType" required>
                            <mat-radio-button class="example-radio-button" *ngFor="let selectedOption of patientOptions"
                              [value]="selectedOption">
                              {{ selectedOption }}
                            </mat-radio-button>
                          </mat-radio-group>
                          <div *ngIf="patient.invalid && patient.touched" class="alert alert-danger">
                            Patient field is required
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style="padding-left: 2em; ">
                      <div class="row" style="margin-top: 15px;">
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput name="first_name" [(ngModel)]="first_name" placeholder="First Name"
                              autocomplete="disabled" required />
                          </mat-form-field>
                        </div>
                        <div class="col-md-6">
                          <mat-form-field class="example-full-width">
                            <input matInput name="last_name" [(ngModel)]="last_name" placeholder="Last Name"
                              autocomplete="disabled" required />
                          </mat-form-field>
                        </div>
                      </div>
                      <div class="row" *ngIf="!calendar_configurations.hideDateOfBirth">
                        <div class="col-md-12">
                          <mat-form-field>
                            <mat-label> Date of Birth ({{dateFormat}})</mat-label>
                            <input matInput name="dateOfBirth" #dateOfBirth="ngModel" [mask]="birthdateInputMask"
                              [validation]="true" [(ngModel)]="birthDate" (keyup)="isValidDate(formData)"
                              autocomplete="disabled" [dropSpecialCharacters]="false" required>
                            <mat-error *ngIf="
                            (dateOfBirth.invalid) &&
                              (dateOfBirth.dirty || dateOfBirth.touched)
                            ">
                              Please Enter valid birthdate
                            </mat-error>
                            <!-- <input matInput name="birthDate" [(ngModel)]="birthDate" "
                              [value]="dateInput.value | date:dateFormat" autocomplete="disabled"
                              required> -->
                          </mat-form-field>
                        </div>
                      </div>

                      <div class="row">
                        <div class="col-md-12">
                          <mat-form-field class="example-full-width">
                            <input matInput id="email" #email="ngModel" name="email" [(ngModel)]="email_id"
                              placeholder="Email" (keyup)="convertToLowercase()"
                              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,20}$" autocomplete="disabled" required />
                          </mat-form-field>
                          <mat-error *ngIf="email.touched">
                            <span style="margin-top: -1em;" *ngIf="
                                   email.invalid &&
                               (email.dirty || email.touched) &&
                               email.errors.pattern">
                              Email format should be
                              <small><b>joe@abc.com</b></small>
                            </span>
                          </mat-error>
                        </div>
                      </div>

                      <div id="mobileView" class="row">
                        <div class="col-xs-4 ">
                          <mat-form-field>
                              <input type="text" class="select-field w-select" name="countryCode"
                                [(ngModel)]="countryCode" matInput autocomplete="new-password" (keydown)="$event.preventDefault();"
                                [matMenuTriggerFor]="belowMenuMobile" #countryCodeSearch required>
                                <mat-icon (click)="countryCodeSearch.click()" matSuffix> keyboard_arrow_down</mat-icon>
                          </mat-form-field>                          
                        </div>
                        <div style="max-height: 200px;overflow: hidden;">
                          <mat-menu style="margin-top: 1em;"  #belowMenuMobile="matMenu" yPosition="below" autoActiveFirstOption>
                            <div 
                              style="margin-left: 1em;margin-right: 1em;">
                              <input type="text" class="form-control"  style="width: 100%;" data-name="Email" #searchInput
                                (blur)="searchInput.focus()" placeholder="Search by Country / Code"
                                (keyup)="searchCountry($event)" (click)="$event.stopPropagation()">
                            </div>
                            <div style="height: 200px; overflow-y: auto;">
                              <span *ngFor="let country of searchedCountries"
                                (click)="slectedCountry(country.dial_code)" mat-menu-item>{{country.name}}
                                {{country.dial_code}}</span>
                            </div>
                          </mat-menu>
                        </div>
                        <div class="col-xs-8" style="position: relative;">
                          <mat-form-field class="example-full-width">
                            <input type="text" matInput id="phone_no" #phone_no="ngModel" placeholder="Phone number"
                              name="phone_no" [mask]="phoneNumberInputMask" [validation]="true" autocomplete="disabled"
                              [ngModel]="phone"
                              (ngModelChange)="lookupNumber(phone = $event, countryCode, countryCodes)" required>
                            <div *ngIf="(!numberStatus || showLoading)" style="right: 1em;top:0.5em;position:absolute;">
                              <div id="spinner" *ngIf="phone.length == 10 && showLoading == true">
                                <mat-spinner matSuffix [diameter]="20" color="accent"></mat-spinner>
                              </div>
                            </div>
                          </mat-form-field>
                          <mat-error *ngIf="numberStatus !== undefined && !phone_no.errors?.required">
                            <div *ngIf="(numberStatus.status === 'invalid')">Please Enter Valid Mobile
                              Number.
                            </div>
                          </mat-error>
                          <mat-error *ngIf="
                                            phone_no.invalid &&
                                            (phone_no.dirty || phone_no.touched) &&
                                            phone_no.errors.required
                                          ">
                            Please Enter phone number
                          </mat-error>
                        
                        </div>
                      </div>
                      <div id="deskView" class="row">
                        <div class="col-md-3">
                          <mat-form-field>

                            <input type="text" class="select-field w-select" name="countryCode" name="countryCode"
                              [(ngModel)]="countryCode" matInput autocomplete="new-password" (keydown)="$event.preventDefault();"
                              [matMenuTriggerFor]="belowMenuDeskTop" #countryCodeSearchDesk required>
                              <mat-icon style="align-items: end;" (click)="countryCodeSearchDesk.click()" matSuffix> keyboard_arrow_down</mat-icon>
                        
                          </mat-form-field>
                          
                          <div style="max-height: 200px;overflow: hidden;">
                            <mat-menu style="margin-top: 1em;" #belowMenuDeskTop="matMenu" yPosition="below" autoActiveFirstOption>
                              <div id="w-node-_8308320a-a396-9646-7eb0-6fa2c9047ba0-b57daefc"
                                style="margin-left: 1em;margin-right: 1em;">
                                <input type="text" class="form-control" style="width: 100%;" data-name="Email" #searchInput1
                                  (blur)="searchInput1.focus()" placeholder="Search by Country / Code"
                                  (keyup)="searchCountry($event)" (click)="$event.stopPropagation()">
                              </div>
                              <div style="height: 200px; overflow-y: auto;">
                                <span *ngFor="let country of searchedCountries"
                                  (click)="slectedCountry(country.dial_code)" mat-menu-item>{{country.name}}
                                  {{country.dial_code}}</span>
                              </div>
                            </mat-menu>
                          </div>
                        </div>
                        <div class="col-md-9" style="width: 75%;padding-bottom: 0px; position: relative;">
                          <mat-form-field class="example-full-width" style="padding: bottom 0;">
                            <input type="text" style="padding: bottom 0;" matInput id=" phone_no" #phone_no="ngModel"
                              placeholder="Phone number" name="phone_no" [mask]="phoneNumberInputMask" [ngModel]="phone"
                              (ngModelChange)="lookupNumber(phone = $event, countryCode, countryCodes)" required />
                            <div #spinner *ngIf="(!numberStatus || showLoading)"
                              style="right: 1em;top:0.5em;position:absolute;">
                              <div *ngIf="phone.length == 10 && showLoading == true">
                                <mat-spinner matSuffix [diameter]="20" color="accent"></mat-spinner>
                              </div>
                            </div>
                          </mat-form-field>
                          <mat-error *ngIf="numberStatus !== undefined && !phone_no.errors?.required">
                            <div *ngIf="(numberStatus.status === 'invalid')">Please Enter Valid Mobile
                              Number.
                            </div>
                          </mat-error>
                          <mat-error *ngIf="
                                              phone_no.invalid &&
                                              (phone_no.dirty || phone_no.touched) &&
                                              phone_no.errors.required
                                            ">
                            Please Enter phone number
                          </mat-error>
                          <!--<div
                            *ngIf="(phone_no.dirty || phone_no.touched) && phone_no.errors?.required || phone_no.length < 1">
                            <p style="color:red;">Phone no. is required.</p>
                          </div>-->
                        </div>
                        <!--<ng-template #status>
                          <div *ngIf="(numberStatus.status === 'valid') || !showConfirm"></div>
                          <p style="color:red;" *ngIf="(numberStatus.status === 'invalid') || showConfirm">
                            Number is invalid</p>
                        </ng-template>-->
                      </div>
                    </div>
                    <!-- id="servicesSection" -->
                    <div class="col-md-12" style="padding-left: 1.5em;">
                      <div class="row" *ngIf="services.enabled == true">
                        <div *ngIf="services.showFullScreen==false;else showFullScreen">
                          <div id="desk-services">
                            <div *ngIf="services.multiSelect == false">
                              <h4 style="margin-left: 25px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatment you are
                                looking
                                for:'}}
                              </h4>
                              <mat-radio-group [class.has-error]="Selctservices.invalid && Selctservices.touched"
                                aria-labelledby="example-radio-group-label" #Selctservices="ngModel" name="service"
                                [ngModel]="services_chosen" required>
                                <div *ngFor="let service of services.provided; let i = index">
                                  <div *ngIf="i%2==0" class="col-md-12">
                                    <div class="col-md-6">
                                      <div style="margin-left: -1.5em;" class="col-md-2" id="servicesSlectButton">
                                        <mat-radio-button (change)="servicesSelectedByRadioButton(i)"
                                          [value]="services.provided[i]">
                                        </mat-radio-button>
                                      </div>
                                      <div class="col-md-10">
                                        {{services.provided[i]}}
                                      </div>
                                    </div>
                                    <div *ngIf="!(i+1==services.provided.length)" class="col-md-6">
                                      <div class="col-md-2 " id="servicesSlectButton">
                                        <mat-radio-button [value]="services.provided[i+1]">
                                        </mat-radio-button>
                                      </div>
                                      <div class="col-md-10">
                                        {{services.provided[i+1]}}
                                      </div>
                                    </div>
                                  </div>
                                  <div *ngIf="Selctservices.invalid && Selctservices.touched"
                                    class="alert alert-danger">
                                    Patient field is required
                                  </div>
                                </div>
                              </mat-radio-group>
                            </div>
                            <div *ngIf="services.multiSelect == true" style="padding-left: 0.5em; ">
                              <h4 style="margin-left: 15px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatments you are
                                looking
                                for:'}}
                              </h4>
                              <section class="example-section" *ngFor="let i=index;let service of services.provided">
                                <div *ngIf="i%2==0" class="col-md-12">
                                  <div class="col-md-6">
                                    <div class="col-md-2" id="servicesSlectButton">
                                      <mat-checkbox class="example-margin" name="{{ servicesSelected[i] }}"
                                        (change)="chekedServices()" [(ngModel)]="servicesSelected[i]"
                                        [ngModelOptions]="{standalone: true}">
                                      </mat-checkbox>
                                    </div>
                                    <div class="col-md-10">
                                      {{ services.provided[i] }}
                                    </div>
                                  </div>
                                  <div *ngIf="!(i+1==services.provided.length)" class="col-md-6">
                                    <div class="col-md-2" id="servicesSlectButton">
                                      <mat-checkbox class="example-margin" name="{{ servicesSelected[i+1] }}"
                                        (change)="chekedServices()" [(ngModel)]="servicesSelected[i+1]"
                                        [ngModelOptions]="{standalone: true}">
                                      </mat-checkbox>
                                    </div>
                                    <div class="col-md-10">
                                      {{ services.provided[i+1] }}
                                    </div>

                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                          <div id="mobile-services">
                            <div style="margin-left: 0.5em" *ngIf="services.multiSelect == false">
                              <h4 style="padding-left: 15px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatment you are
                                looking
                                for:'}}
                              </h4>
                              <mat-radio-group [class.has-error]="Selctservices.invalid && Selctservices.touched"
                                aria-labelledby="example-radio-group-label" #Selctservices="ngModel" name="service"
                                [ngModel]="services_chosen" required>
                                <div *ngFor="let service of services.provided; let i = index">
                                  <div class="col-md-12">

                                    <!-- <div class="col-md-2" id="servicesSlectButton">
                                      <mat-radio-button [value]="services.provided[i]">
                                      </mat-radio-button>
                                    </div>
                                    <div class="col-md-10">
                                      {{services.provided[i]}}
                                    </div> -->

                                    <span>
                                      <mat-radio-button (change)="servicesSelectedByRadioButton(i)"
                                        [value]="services.provided[i]">
                                      </mat-radio-button>
                                    </span>
                                    <span>
                                      {{services.provided[i]}}
                                    </span>

                                  </div>
                                  <div *ngIf="Selctservices.invalid && Selctservices.touched"
                                    class="alert alert-danger">
                                    Services field is required
                                  </div>
                                </div>
                              </mat-radio-group>
                            </div>
                            <div *ngIf="services.multiSelect == true" style="padding-left: 0.5em; ">
                              <h4 style="margin-left: 15px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatments you are
                                looking
                                for:'}}
                              </h4>
                              <section style="padding-top: 5px;" class="example-section"
                                *ngFor="let i=index;let service of services.provided">
                                <div class="col-md-12">
                                  <span>
                                    <mat-checkbox class="example-margin" name="{{ servicesSelected[i] }}"
                                      (change)="chekedServices()" [(ngModel)]="servicesSelected[i]"
                                      [ngModelOptions]="{standalone: true}">
                                    </mat-checkbox>
                                  </span>
                                  <span>
                                    {{ services.provided[i] }}
                                  </span>
                                  <!-- <div class="col-md-2" id="servicesSlectButton">
                                   
                                  </div>
                                  <div class="col-md-10">
                                   
                                  </div> -->

                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                        <ng-template #showFullScreen>
                          <div id="desk-services">
                            <div *ngIf="services.multiSelect == false">
                              <h4 style="margin-left: 20px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatment you are
                                looking
                                for:'}}
                              </h4>
                              <mat-radio-group [class.has-error]="Selctservices.invalid && Selctservices.touched"
                                aria-labelledby="example-radio-group-label" #Selctservices="ngModel" name="service"
                                [ngModel]="services_chosen" required>
                                <div *ngFor="let service of services.provided; let i = index">
                                  <div class="col-md-12">
                                    <span>
                                      <mat-radio-button (change)="servicesSelectedByRadioButton(i)"
                                        [value]="services.provided[i]">
                                      </mat-radio-button>
                                    </span>
                                    <span>
                                      {{services.provided[i]}}
                                    </span>

                                  </div>
                                  <div *ngIf="Selctservices.invalid && Selctservices.touched"
                                    class="alert alert-danger">
                                    Services field is required
                                  </div>
                                </div>
                              </mat-radio-group>
                            </div>
                            <div *ngIf="services.multiSelect == true" style="padding-left: 0.5em; ">
                              <h4 style="margin-left: 15px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatments you are
                                looking
                                for:'}}
                              </h4>
                              <section style="padding-top: 5px;" class="example-section"
                                *ngFor="let i=index;let service of services.provided">
                                <div class="col-md-12">
                                  <span>
                                    <mat-checkbox class="example-margin" name="{{ servicesSelected[i] }}"
                                      (change)="chekedServices()" [(ngModel)]="servicesSelected[i]"
                                      [ngModelOptions]="{standalone: true}">
                                    </mat-checkbox>
                                  </span>
                                  <span>
                                    {{ services.provided[i] }}
                                  </span>
                                  <!-- <div class="col-md-2" id="servicesSlectButton">
                                 
                                </div>
                                <div class="col-md-10">
                                 
                                </div> -->
                                </div>
                              </section>
                            </div>
                          </div>
                          <div id="mobile-services">
                            <div *ngIf="services.multiSelect == false">
                              <h4 style="margin-left: 20px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatment you are
                                looking
                                for:'}}
                              </h4>
                              <mat-radio-group [class.has-error]="Selctservices.invalid && Selctservices.touched"
                                aria-labelledby="example-radio-group-label" #Selctservices="ngModel" name="service"
                                [ngModel]="services_chosen" required>
                                <div *ngFor="let service of services.provided; let i = index">
                                  <div class="col-md-12">

                                    <!-- <div class="col-md-2" id="servicesSlectButton">
                                      <mat-radio-button [value]="services.provided[i]">
                                      </mat-radio-button>
                                    </div>
                                    <div class="col-md-10">
                                      {{services.provided[i]}}
                                    </div> -->

                                    <span>
                                      <mat-radio-button (change)="servicesSelectedByRadioButton(i)"
                                        [value]="services.provided[i]">
                                      </mat-radio-button>
                                    </span>
                                    <span>
                                      {{services.provided[i]}}
                                    </span>

                                  </div>
                                  <div *ngIf="Selctservices.invalid && Selctservices.touched"
                                    class="alert alert-danger">
                                    Services field is required
                                  </div>
                                </div>
                              </mat-radio-group>
                            </div>
                            <div *ngIf="services.multiSelect == true" style="padding-left: 0.5em; ">
                              <h4 style="margin-left: 15px;">{{services.displayText?services.displayText:'Please
                                choose
                                the
                                treatments you are
                                looking
                                for:'}}
                              </h4>
                              <section style="padding-top: 5px;" class="example-section"
                                *ngFor="let i=index;let service of services.provided">
                                <div class="col-md-12">
                                  <span>
                                    <mat-checkbox class="example-margin" name="{{ servicesSelected[i] }}"
                                      (change)="chekedServices()" [(ngModel)]="servicesSelected[i]"
                                      [ngModelOptions]="{standalone: true}">
                                    </mat-checkbox>
                                  </span>
                                  <span>
                                    {{ services.provided[i] }}
                                  </span>
                                  <!-- <div class="col-md-2" id="servicesSlectButton">
                                   
                                  </div>
                                  <div class="col-md-10">
                                   
                                  </div> -->

                                </div>
                              </section>
                            </div>
                          </div>
                        </ng-template>
                      </div>

                    </div>
                  </div>
                  <div id="mobileViewAgrement" class="row" style=" width: 100%;">
                    <div class="col-md-12 text-center">
                      <span>
                        <mat-checkbox class="example-margin" name="check_send_text_reminder"
                          [(ngModel)]="send_text_reminders">
                        </mat-checkbox>
                      </span>
                      <span>
                        I agree to
                        receive text/email messages for appointment
                        reminders
                        and feedback
                      </span>
                    </div>

                    <div id="confirmButton" class="col-md-12 text-center">
                      <button *ngIf="!isContinue" type="submit" class="btn"
                        [disabled]="(!formData.valid || !this.checkBoxValidation) || showLoading || showConfirm">
                        Confirm
                        <mat-icon *ngIf="appointmentConfirmLoading">
                          <mat-spinner diameter="20"></mat-spinner>
                        </mat-icon>
                      </button>
                      <button *ngIf="isContinue" class="btn" type="button" (click)="redirectTo(formData)"
                        [disabled]="!formData.valid  || !this.checkBoxValidation">
                        Continue
                      </button>
                    </div>
                  </div>
                </div>
                <div class="col-md-3" id="summary">
                  <app-appointment-details [selectedServices]="services_chosen"></app-appointment-details>
                </div>
              </div>
              <div id="footerOfDeskview" class="row" style="width:96%;">
                <div class="col-md-12 text-center">
                  <div class="col-md-9">
                    <span>
                      <mat-checkbox class="example-margin" name="send_text_reminders" [(ngModel)]="send_text_reminders">
                      </mat-checkbox>
                    </span>
                    <span>
                      I agree to
                      receive text/email messages for appointment
                      reminders
                      and feedback
                    </span>
                  </div>
                  <div class="col-md-3">
                    <div id="confirmButton">
                      <button *ngIf="!isContinue" type="submit" class="btn"
                        [disabled]="(!formData.valid || !this.checkBoxValidation) || showLoading || showConfirm">
                        Confirm
                        <mat-icon *ngIf="appointmentConfirmLoading">
                          <mat-spinner diameter="20"></mat-spinner>
                        </mat-icon>
                      </button>

                      <button *ngIf="isContinue" class="btn" type="button" (click)="redirectTo(formData)"
                        [disabled]="!formData.valid || !this.checkBoxValidation || showLoading || showConfirm">
                        Continue
                      </button>
                    </div>
                  </div>

                </div>


              </div>
            </form>

            <!--<ng-template #dialogRef>
              <app-otp-verification></app-otp-verification>
            </ng-template>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer">
  <app-footer [back]="!formSubmitted || showOtpDialog" [summary]="!formSubmitted || showOtpDialog" [next]="isContinue"
    [confirm]="!isContinue" [showConfirm]="this.showConfirm"
    [nextBtnDisabled]="(!formData.valid || !this.checkBoxValidation) || this.showConfirm == true || this.showLoading == true"
    (summaryShow)="summaryShow($event)" (goBack)="goBack()" (nextButtonClick)="nextButtonOfFooteClick()">
  </app-footer>
</div>