import { Injectable } from '@angular/core';
// import "firebase/firestore";
import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/functions";
import { Calendar } from '../_models/interfaces';
// import { DocumentReference } from 'angularfire2/firestore';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  // account_id:string;
  constructor() { }

  async getCalendars(accoutId: string, providerRef: firebase.firestore.DocumentReference, locationRef: firebase.firestore.DocumentReference): Promise<Array<Calendar>> {
    const calendarSnap = await firebase.firestore().collection("accounts").doc(accoutId).collection("calendars")
      .where("providerReference", "==", providerRef)
      .where("locationReference", "==", locationRef)
      .get();
    let calendars = [];
    calendarSnap.docs.forEach(
      calendar => {
        let calData = calendar.data();
        if(!calData.inactive){
        calendars.push({ 'slug': calData.slug, 'calendarRef': calendar.ref, name: calData.name });
        }
      });
    return calendars;
  }
}
