import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import firebase from 'firebase/app';
import "firebase/firestore";
import * as Sentry from "@sentry/angular";
// import { settings } from 'node:cluster';

if (environment.production) {
  enableProdMode();
  if (window) {
    window.console.log = function () { };
  }
}

firebase.initializeApp(environment.mktg_firebase);
const firestore = firebase.firestore();



Sentry.init({
  dsn: "https://37e3535edd394d59aaf1d7f834c1d25b@o4504280845516800.ingest.sentry.io/4505205304262656",
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
// firestore.settings({ timestampsInSnapshots: true });


const urlParams = new URLSearchParams(window.location.search);
const source = urlParams.get('source');
let color1 = urlParams.get('color1');
let color2 = urlParams.get('color2');
let color3 = urlParams.get('color3');
let color4 = urlParams.get('color4');
let color5 = urlParams.get('color5');
let urlParts = document.location.pathname.split('/');
if (urlParts[1] == 'payments' || urlParts[1] == 'calendar') {
  let subAccountRef = null;
  firestore.collection('accounts').where('account_slug', '==', urlParts[2]).get().then(
    async querySnapshot => {
      let gtm_container_id = querySnapshot.docs[0].get('gtm_container_id');
      let accountData = querySnapshot.docs[0].data();

      if (accountData && accountData.mergedAccount) {
        let calendarSlug = urlParts[3]
        let calendarSnapshot = await querySnapshot.docs[0].ref.collection('calendars').where('slug', '==', calendarSlug).get();
        if (calendarSnapshot.docs.length > 0) {
          let calendarData = calendarSnapshot.docs[0].data();
          if (calendarData.subAccountRef) {
            subAccountRef = calendarData.subAccountRef;
            setColorTheme(calendarData.subAccountRef);
            let subAccountSnapshot = await calendarData.subAccountRef.get();
            if (subAccountSnapshot.exists) {
              gtm_container_id = subAccountSnapshot.data().gtm_container_id;
            }
          }
        }
      }

      if (gtm_container_id !== undefined && source) {
        const trackingScript = document.createElement('script');
        trackingScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                 })(window,document,'script','dataLayer','${gtm_container_id}');`;
        document.head.appendChild(trackingScript);
      } else {
        window['dataLayer'] = [];
      }
      if (!subAccountRef) {
        setColorTheme(null);
      }
    }
  );
} else {
  window['dataLayer'] = [];
  setColorTheme(null);
}

async function setColorTheme(subAccountRef?: any) {
  firestore.collection('accounts').where('account_slug', '==', urlParts[2]).get().then(async (querySnap) => {
    let accountRef = null;
    if (querySnap.docs.length > 0) {
      accountRef = querySnap.docs[0].ref;
    } else if (querySnap.docs.length == 0 && (urlParts[1] === 'reschedule' || urlParts[1] === 'cancel' || urlParts[1] === 'appointment-request')) {
      let reschdeleSnapshot = await firebase.firestore().collection("reschedules").doc(urlParts[2]).get();
      let rescheduleData = reschdeleSnapshot.data();
      accountRef = firebase.firestore().collection("accounts").doc(rescheduleData.appt_ref.parent.parent.id);
      let redirectUrl = rescheduleData.redirectUrl;
      let calendarSlug = redirectUrl.split('/')[redirectUrl.split('/').length - 1];
      let calendarSnapshot = await accountRef.collection('calendars').where('slug', '==', calendarSlug).get();
      if (calendarSnapshot.docs.length > 0) {
        let calendarData = calendarSnapshot.docs[0].data();
        if (calendarData.subAccountRef) {
          subAccountRef = calendarData.subAccountRef;
        }
      }
    }
    let colorThemedata: any = {}
    if (accountRef) {

      if (subAccountRef) {
        let colorThemeSnapshot = await accountRef.collection('settings').where('type', '==', 'appointment-theme').where("subAccountRef", "==", subAccountRef).get();
        if (colorThemeSnapshot.docs.length > 0) {
          colorThemedata = colorThemeSnapshot.docs[0].data();
        }
      } else {
        let colorThemeSnapshot = await accountRef.collection('settings').where('type', '==', 'appointment-theme').get();
        if (colorThemeSnapshot.docs.length > 0) {
          colorThemedata = colorThemeSnapshot.docs[0].data();
        }
      }

      console.log(colorThemedata)


      // locations color settings
      let location = colorThemedata.location ? colorThemedata.location : {};
      let locationBackground = location.backgroundColor ? location.backgroundColor : '#d8f1e4';
      let locationTesxtColor = location.textColor ? location.textColor : 'black';
      document.documentElement.style.setProperty('--location-bg-color', locationBackground);
      document.documentElement.style.setProperty('--location-text-color', locationTesxtColor);


      // provider color settings
      let provider = colorThemedata.provider ? colorThemedata.provider : {};
      let providerBgColor = provider.backgroundColor ? provider.backgroundColor : '#d8f1e4';
      let providerTextColor = provider.textColor ? provider.textColor : 'black';
      document.documentElement.style.setProperty('--provider-bg-color', providerBgColor);
      document.documentElement.style.setProperty('--provider-txt-color', providerTextColor);

      // Book Appt button color settings
      let bookAppointmentButton = colorThemedata.bookAppointmentButton ? colorThemedata.bookAppointmentButton : {};
      let bookAppointmentButtonBackgroundColor = bookAppointmentButton.backgroundColor ? bookAppointmentButton.backgroundColor : '#a9c7a5';
      let bookAppointmentButtonHoverColor = bookAppointmentButton.hoverColor ? bookAppointmentButton.hoverColor : '#7b8d78';
      let BookApointmentButtonTextColor = bookAppointmentButton.textColor ? bookAppointmentButton.textColor : 'white';
      let BookApointmentButtonHoverTextColor = bookAppointmentButton.hovertextColor ? bookAppointmentButton.hovertextColor : 'white';
      document.documentElement.style.setProperty('--appointment-button-bg-color', bookAppointmentButtonBackgroundColor);
      document.documentElement.style.setProperty('--appointment-button-hover-color', bookAppointmentButtonHoverColor);
      document.documentElement.style.setProperty('--appointment-button-text-color', BookApointmentButtonTextColor);
      document.documentElement.style.setProperty('--appointment-button-hover-text-color', BookApointmentButtonHoverTextColor);

      // Header color settings
      let header = colorThemedata.header ? colorThemedata.header : {}
      let headerbgColor = header.backgroundColor ? header.backgroundColor : '#5f9362';
      let headerTextColor = header.textColor ? header.textColor : 'white';
      document.documentElement.style.setProperty('--header-bg-color', headerbgColor);
      document.documentElement.style.setProperty('--header-txt-color', headerTextColor);

      //iconsColor color setting
      let iconsColor = colorThemedata.iconsColor ? colorThemedata.iconsColor : '#626362'
      document.documentElement.style.setProperty('--icon-color', iconsColor);


      //Slot
      let slotsBackgroundColor = colorThemedata.slotsBackgroundColor ? colorThemedata.slotsBackgroundColor : '#d8f1e4'
      document.documentElement.style.setProperty('--slots-column-bg-color', slotsBackgroundColor);

      let slot = colorThemedata.slot ? colorThemedata.slot : {}
      document.documentElement.style.setProperty('--slots-bg-color', slot.backGroundColor ? slot.backGroundColor : '#f2f2f2');
      document.documentElement.style.setProperty('--slots-hover-color', slot.hoverColor ? slot.hoverColor : 'white');

      //Butttons
      let button = colorThemedata.button ? colorThemedata.button : {}
      document.documentElement.style.setProperty('--button-bg-color', button.backGroundColor ? button.backGroundColor : '#5f9362');
      document.documentElement.style.setProperty('--button-text-color', button.textColor ? button.textColor : 'white');

      //strip Calender
      let stripCalender = colorThemedata.stripClaender ? colorThemedata.stripClaender : {}
      let slotTextColor = stripCalender.slotTextColor ? stripCalender.slotTextColor : '#bdc0ff;'
      let monthTitleBackGroundColor = stripCalender.monthTitleBackGroundColor ? stripCalender.monthTitleBackGroundColor : '#bdc0ff;'

      document.documentElement.style.setProperty('--month-title-bg-color', monthTitleBackGroundColor);
      document.documentElement.style.setProperty('--slot-text-color', slotTextColor);

      let inputColor = colorThemedata.inputColor ? colorThemedata.inputColor : "#ff4081";
      document.documentElement.style.setProperty('--input-color--', inputColor);

      let backButtonHoverColor = colorThemedata.backButtonHoverColor ? colorThemedata.backButtonHoverColor : "black";
      document.documentElement.style.setProperty('--back--button-hover-color--', inputColor);

      if (color5) {
        document.documentElement.style.setProperty('--header-bg-color', color5);
        document.documentElement.style.setProperty('--button-bg-color', color1);
        document.documentElement.style.setProperty('--icon-color', color2);
        document.documentElement.style.setProperty('--input-color--', color2);
        document.documentElement.style.setProperty('--month-title-bg-color', color2);
        document.documentElement.style.setProperty('--month-title-bg-color', color2);
        document.documentElement.style.setProperty('--slots-bg-color', color4);
        document.documentElement.style.setProperty('--slots-column-bg-color', color3);
        document.documentElement.style.setProperty('--slot-text-color', "#000000");
        document.documentElement.style.setProperty('--back--button-hover-color--', color2);
        document.documentElement.style.setProperty('--location-bg-color', color4);
        document.documentElement.style.setProperty('--provider-bg-color', color4);
      };
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));

    } else {

      let data: any = {}
      //iconsColor color setting
      document.documentElement.style.setProperty('--icon-color', '#626362');

      //Slot
      document.documentElement.style.setProperty('--slots-column-bg-color', '#bec2fd');
      document.documentElement.style.setProperty('--slots-bg-color', '#f2f2f2');
      document.documentElement.style.setProperty('--slots-hover-color', 'white');

      //Butttons
      let button = data.button ? data.button : {}
      document.documentElement.style.setProperty('--button-bg-color', '#3f51b5');
      document.documentElement.style.setProperty('--button-text-color', 'white');

      //strip Calender
      document.documentElement.style.setProperty('--month-title-bg-color', '#bdc0ff');
      document.documentElement.style.setProperty('--slot-text-color', '#bdc0ff');
      document.documentElement.style.setProperty('--input-color--', "#ff4081");
      document.documentElement.style.setProperty('--header-bg-color', '#3f51b5');

      if (color5) {
        document.documentElement.style.setProperty('--header-bg-color', color5);
        document.documentElement.style.setProperty('--button-bg-color', color1);
        document.documentElement.style.setProperty('--icon-color', color2);
        document.documentElement.style.setProperty('--input-color--', color2);
        document.documentElement.style.setProperty('--month-title-bg-color', color2);
        document.documentElement.style.setProperty('--month-title-bg-color', color2);
        document.documentElement.style.setProperty('--slots-bg-color', color4);
        document.documentElement.style.setProperty('--slots-column-bg-color', color3);
        document.documentElement.style.setProperty('--slot-text-color', "#000000");
        document.documentElement.style.setProperty('--back--button-hover-color--', color2);
        document.documentElement.style.setProperty('--location-bg-color', color4);
        document.documentElement.style.setProperty('--provider-bg-color', color4);
      };
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.log(err));
    }
  });
}





