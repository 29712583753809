<div class="container-fluid">
  <div class="center">
    <div *ngIf="status === 'confirmed'">
      <i class="material-icons status-symbol">check_circle</i>
      <p class="highlight">Appointment Rescheduled!</p>
      <p class="center" class="highlight" *ngIf="appointmentService.providersName !== undefined">
        Your appointment has been rescheduled with {{ providersName }} at
        {{ appointmentWith }}
      </p>
      <p class="center" class="highlight"
        *ngIf="appointmentService.providersName === undefined && appointmentService.multipleLocations=== true">
        Your appointment has been rescheduled with {{ appointmentWith }} 
      </p>

      <p class="center" class="highlight"
        *ngIf="appointmentService.providersName === undefined && appointmentService.multipleLocations== false">Your
        appointment has been rescheduled with {{ appointmentWith }}</p>
      <p>
        <strong>{{ selectedDate }}</strong>,
        <strong>{{ appointmentService.convertToLocalTime(selectedSlot.start) }} -
          {{ appointmentService.convertToLocalTime(selectedSlot.end) }}
          {{ appointmentService.getLocalTimezoneAbbr() }}</strong>
      </p>
      <p *ngIf="selectedServices !== ''">
        <strong>Treatments you are looking for</strong><br />
        {{ selectedServices }}
      </p>
      <p style="margin-top: 20px;">An invitation has been emailed to you.</p>
      <div class="confirmationMsg" [innerHTML]="confirmationMsg"></div>
    </div>
    <div *ngIf="status === 'unconfirmed' && errorIn === 'saving'">
      <i class="material-icons status-symbol">cancel</i>
      <p class="highlight">
        There was an error while adding your appointment. Please try again.
      </p>
      <button class="back-btn" (click)="router.navigateByUrl('/confirm-appointment')" mat-raised-button color="primary">
        Re-confirm appointment
      </button>
    </div>
    <div *ngIf="status === 'unconfirmed' && errorIn === 'confirming'">
      <i class="material-icons status-symbol">cancel</i>
      <p class="highlight">Sorry, this slot has now become unavailable.</p>
      <p>Please pick a different date and/or slot.</p>
      <button class="back-btn" (click)="goBack()" mat-raised-button color="primary">
        Pick Date
      </button>
    </div>
    <div *ngIf="status === 'rescheduled'">
      <i class="material-icons status-symbol">history</i>
      <p class="highlight">Appointment Rescheduled!</p>

      <p *ngIf="appointmentService.providersName !== undefined">
        Your appointment with {{ providersName }} at {{ appointmentWith }} has been rescheduled.
      </p>
      <p class="center" class="highlight" *ngIf="appointmentService.providersName == undefined">
        Your appointment has been rescheduled with {{ appointmentWith }}
      </p>
      <p class="strike" *ngIf="oldProvider!== undefined">{{oldProvider}}, {{ oldDate | date: "MMMM d, y h:mm a" }}</p>
      <p *ngIf="oldProvider=== undefined" class="strike">{{ oldDate | date: "MMMM d, y h:mm a" }}</p>
      <p>
        <strong>{{ selectedDate }}</strong>,
        <strong>{{ appointmentService.convertToLocalTime(selectedSlot.start) }} -
          {{ appointmentService.convertToLocalTime(selectedSlot.end) }}
          {{ appointmentService.getLocalTimezoneAbbr() }}</strong>
      </p>
      <p *ngIf="selectedServices !== ''">
        <strong>Treatments you are looking for</strong><br />
        {{ selectedServices }}
      </p>
      <p style="margin-top: 20px;">An invitation has been emailed to you.</p>
      <div class="confirmationMsg" [innerHTML]="confirmationMsg"></div>
    </div>
  </div>
</div>