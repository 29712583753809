import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import firebase from 'firebase/app';
// import { AngularFireDatabase } from "angularfire2/database";
// import {  AngularFirestore,  AngularFirestoreDocument,} from "angularfire2/firestore";
// import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import * as moment from "moment-timezone";
import { environment } from "../../environments/environment";
import { ActivatedRoute, ActivationStart, NavigationEnd, NavigationStart, Router } from "@angular/router";
import { DatePipe } from "@angular/common";
import { ConfirmationDialogComponent } from "../recurring-appointment/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { element } from "protractor";
import { textChangeRangeIsUnchanged } from "typescript";
import { forkJoin, Subject } from "rxjs";
import { cpuUsage } from "process";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class AppointmentService {
  public accDetails: any = {};
  public acc_slug: string;
  public provider_slug: string;
  public acc_name: string;
  public calendar_slug: string;
  public group_slug: string;
  public group_type: string;
  public multipleLocations: boolean;
  public multipleProviders: boolean;
  public providerLocations: boolean;
  public calendarRef: any;
  public providersName: string;
  public address: any;
  private selectedDate: any;
  private selectedSlot: any;
  public timezone: string;
  private source: string;
  private settings: any;
  private bookedOn: any;
  public rescheduleDoc: string;
  public appt_ref: any;
  public contact_ref: any;
  public formData: any;
  public countryCode: any;
  public pageUrl: string = null;

  public providersGroupData: any = [];
  public providerId: string;

  appointment: any;
  bookingDate: any;
  providerRef: any;
  private contactRef: any;
  public acc_ref: any;
  private createdOn: any;
  private services: string = "";
  url: string;
  appointment_details: any;
  redirectUrl: string;
  private category: string;
  public routerPaths: any[] = []
  recuuringSchedule: any = {};
  recurringIndex: number = -1;
  recuringAppointmentsRefDtails: any = [];
  apptDetailRefId: string
  private slotIndexFound = new Subject<any>();
  slotIndexFound$ = this.slotIndexFound.asObservable();
  stripCalenderStarting: number = -1
  stripCalenderEnding: number = -1
  rollingPeriodStarting: number = -1
  rollingPeriodEnding: number = -1
  public apptConfirmed: boolean = false
  patientContactId: string;
  gclid: any;
  formRespId: string
  leadPipelineId: string
  public numberStatus: any;
  subject = new Subject<string>();
  otpVerified = false;
  referer: string;
  constructor(
    private http: HttpClient,
    private router: Router,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private deviceService: DeviceDetectorService

    // public afs: AngularFirestore
  ) {
    // this.getProvidersName();
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        for (let i = 0; i < this.routerPaths.length; i++) {
          if (event.url === this.routerPaths[i].url) {
            this.routerPaths.splice(i, this.routerPaths.length - i + 1);
          }
        }
        if (event.url.includes("?")) {
          event.url = event.url.split("?")[0];
        }
        let navigationEventObj: any = {}
        navigationEventObj = event
        navigationEventObj.isAutoRoute = false
        this.routerPaths.push(event);
        console.log(this.routerPaths)
      }

    });

    this.route.url.subscribe(async url => {
      let urlParts = document.location.pathname.split('/')
      this.acc_slug = urlParts[2];
      if (urlParts[1] != 'reschedule' && urlParts[1] != 'cancel') {
        let acc = await firebase
          .firestore()
          .collection("accounts")
          .where("account_slug", "==", this.acc_slug)
          .get();
        this.acc_ref = acc.docs[0].ref;
        this.accDetails = acc.docs[0].data();
      }
    })
  }


  async getProvidersCalendar(acc_slug: string, provider_slug: string) {
    this.acc_slug = acc_slug;
    this.provider_slug = provider_slug;
    this.group_type = "providers-groups";
    // this.group_slug = provider_slug;
    // let providersGroupData = [];
    let acc = await firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", this.acc_slug)
      .get();
    this.acc_ref = acc.docs[0].ref;
    this.accDetails = acc.docs[0].data();
    this.acc_name = this.accDetails.name;
    let providerCalendarDoc = await this.acc_ref
      .collection("providers-groups")
      .where("slug", "==", this.provider_slug)
      .get();
    let providerData = {};
    this.providersGroupData = [];
    providerCalendarDoc.docs[0]
      .data()
      .providersReference.forEach((providerRef) => {
        this.getDataOfRefernce(providerRef).then((docData) => {
          providerData = docData;
          let calendarArr = [];
          docData.calendar_link.forEach((calendarRef) => {
            this.getCalendar(calendarRef).then((calendarData) => {
              calendarData["ref"] = calendarRef;
              calendarArr.push(calendarData);
            });
            providerData["calendarData"] = calendarArr;
          });
          this.providersGroupData.push(providerData);
        });
      });
    return this.providersGroupData;
  }

  async getCalendarGroups(acc_slug: string, group_slug: string) {
    this.acc_slug = acc_slug;
    this.group_type = "calendar-groups";

    this.group_slug = group_slug;

    let unresolvedCalendars = [];

    let acc = await firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", this.acc_slug)
      .get();

    this.acc_ref = acc.docs[0].ref;
    this.accDetails = acc.docs[0].data();
    this.acc_name = this.accDetails.name;
    // this.acc_name = acc.docs[0].data().name;

    let calendarGroupDoc = await this.acc_ref
      .collection("calendar-groups")
      .where("slug", "==", this.group_slug)
      .get();

    calendarGroupDoc.docs[0].data().calendars.forEach((calendarRef) => {
      unresolvedCalendars.push(this.getCalendar(calendarRef));
    });

    let calendars = await Promise.all(unresolvedCalendars);
    return calendars;
  }

  async getDataOfRefernce(docRef: any) {
    let doc = await docRef.get();
    let docData = doc.data();
    docData["docId"] = doc.id;
    docData["providerRef"] = doc.ref;
    return docData;
  }

  setGroupSlug(type, slug) {
    this.group_type = type;
    this.group_slug = slug;
  }

  async getCalendar(calendarRef: any) {
    let calendar = await calendarRef.get();
    calendar = calendar.data();
    calendar["reference"] = calendarRef;
    return calendar;
  }

  fetchSettings() {
    return firebase
      .firestore()
      .collection("appointments-settings")
      .doc(`${environment.calendar_settings_id}`)
      .get();
  }

  async setSettings(settings: any) {
    this.settings = settings;
    if (this.settings.subAccountRef) {
      let subAcountSnapShot = await this.settings.subAccountRef.get()
      this.acc_name = subAcountSnapShot.data()['name']
    }
  }

  setPathForBacktracking(acc_slug, groupType, group_slug) {
    let redirectPath = "${groupType}/${acc_slug}/${group_slug}";
  }
  getSettings() {
    return this.settings;
  }

  getSelectedDate() {
    return this.selectedDate;
  }

  getSelectedSlot() {
    return this.selectedSlot;
  }

  getTimezone() {
    return this.timezone;
  }

  getSource() {
    return this.source;
  }

  getCategory() {
    return this.category;
  }

  setSelectedDate(date: any) {
    this.selectedDate = date;
  }

  setSelectedSlot(slot: any) {
    this.selectedSlot = slot;
  }

  setTimezone(timezone: string) {
    this.timezone = timezone;
  }

  setSource(source: string) {
    this.source = source;
  }

  setCategory(category: string) {
    this.category = category;
  }

  getSelectedServices() {
    return this.services;
  }

  setSelectedServices(services: string) {
    this.services = services;
  }

  getAccountRef() {
    return this.acc_ref;
  }

  /*setSelectedCountryCode(countryCode :string){
     this.countryCode : countryCode;
   }*/

  getFormData() {
    return this.formData;
  }
  setFormData(formData: any) {
    this.formData = formData;
  }

  getProviderCalendarData(
    documentID //return providers details along with calendars
  ) {
    let providerObj = this.providersGroupData.filter(
      (doc) => doc.docId === documentID
    );
    return providerObj;
  }

  async checkSlotsAvailability() {
    let bookedSlots = [];
    let query = this.acc_ref.collection("appointments").where("date", "==", this.selectedDate.format("MMMM D, YYYY"))
    if (this.calendarRef && !this.accDetails.requestedAppointment) {
      query = query.where("calendarReference", "==", this.calendarRef)
    }

    const appointmentsRef = await query.get();
    appointmentsRef.forEach((appointment) => {
      let data = appointment.data();
      if ((this.providerRef && this.accDetails.requestedAppointment) || data.pmsType == "Blocked Slot") {
        bookedSlots.push(data);
      }
      else {
        bookedSlots.push(data);
      }

    });

    if (this.accDetails.requestedAppointment) {
      let requestedQuery = this.acc_ref.collection("requested_appointment").where("date", "==", this.selectedDate.format("MMMM D, YYYY"))
      if (this.providerRef && this.accDetails.requestedAppointment) {
        requestedQuery = requestedQuery.where("providerId", "==", this.providerRef.id)
      }
      const requestedApptSnapshot = await requestedQuery.get();
      requestedApptSnapshot.forEach((appointment) => {
        bookedSlots.push(appointment.data());
      });
    }
    return bookedSlots;
  }

  async checkSlotsAvailabilityBetweenDateRange(fromdate, todate) {
    fromdate = this.datepipe.transform(fromdate, 'yyyy/MM/dd');
    todate = this.datepipe.transform(todate, 'yyyy/MM/dd')
    fromdate = new Date(fromdate + " " + "00:00:00")
    todate = new Date(todate + " " + "23:59:59")

    let query = this.acc_ref.collection("appointments")
      .where("appt_start", ">=", fromdate)
      .where('appt_start', '<=', todate);
    if (this.calendarRef && !this.accDetails.requestedAppointment) {
      query = query.where("calendarReference", "==", this.calendarRef)
    }


    let appointmentsRef = await query.get();
    let bookedSlots = [];
    appointmentsRef.forEach((appointment) => {
      let tempApptData = appointment.data()
      if ((this.providerRef && this.accDetails.requestedAppointment) || tempApptData.pmsType == "Blocked Slot") {
        bookedSlots.push(tempApptData);
      }
      else {
        bookedSlots.push(appointment.data());
      }
    });
    if (this.accDetails.requestedAppointment) {
      let requstedSlot = await this.checkRequestedSlotsAvailabilityBetweenDateRange(fromdate, todate);
      bookedSlots = [...bookedSlots, ...requstedSlot]
      return bookedSlots
    }
    else
      return bookedSlots;
    // console.log(bookedSlots)
    // console.log(fromdate)
    // console.log(todate)
  }

  async checkRequestedSlotsAvailabilityBetweenDateRange(fromdate, todate) {

    let query = this.acc_ref.collection("requested_appointment")
      .where("appt_start", ">=", fromdate)
      .where('appt_start', '<=', todate);
    if (this.providerRef && this.accDetails.requestedAppointment) {
      query = query.where("providerId", "==", this.providerRef.id)
    }
    let appointmentsRef = await query.get();
    console.log("Requested Appointment", appointmentsRef);
    let bookedSlots = [];
    appointmentsRef.forEach((appointment) => {
      bookedSlots.push(appointment.data());
    });
    return bookedSlots;
  }

  checkSlotsAvailabilityOfSelctedDate(date) {

    //return firebase.firestore().collection('appointments')
    return this.acc_ref
      .collection("appointments")
      .where("date", "==", date.format("MMMM D, YYYY"))
      .where("calendarReference", "==", this.calendarRef)
      .get()
      .then(
        (appointmentsRef) => {
          let bookedSlots = [];
          appointmentsRef.forEach((appointment) => {
            let slot = {
              slot: appointment.data().slot,
              status: appointment.data().status,
            };
            bookedSlots.push(slot);
          });
          return bookedSlots;
        },
        (error) => {
          console.log(error);
        }
      );


  }
  async confirmAppointmentValidity() {
    //return firebase.firestore().collection('appointments')
    let querySnapshot = await this.acc_ref
      .collection("appointments")
      .where("date", "==", this.selectedDate.format("MMMM D, YYYY"))
      .where(
        "slot",
        "==",
        `${this.selectedSlot.start} - ${this.selectedSlot.end}`
      )
      .where("calendarReference", "==", this.calendarRef)
      .where("status", "==", "booked")
      .get();

    return querySnapshot.empty;
  }
  async confirmRequestedAppointmentValidity() {
    //return firebase.firestore().collection('appointments')
    let appt_start = moment.tz(`${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedSlot.start}`, "YYYY-MM-DD h:mm a", this.timezone).toDate();
    let appt_end = moment.tz(`${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedSlot.end}`, "YYYY-MM-DD h:mm a", this.timezone).toDate()
    let bookedQuery = this.acc_ref
      .collection("appointments")
      .where("appt_start", ">=", appt_start)
      .where(
        "appt_start",
        "<",
        appt_end
      )
      .where("status", "==", "booked")

    if (this.providerRef) {
      bookedQuery = bookedQuery.where("providerId", "==", this.providerRef.id)
    }

    let bookedQuerySnapshot = await bookedQuery.get()

    let query = this.acc_ref
      .collection("requested_appointment")
      .where("appt_start", ">=", appt_start)
      .where(
        "appt_start",
        "<",
        appt_end
      )
      .where("status", "==", "requested")
    if (this.providerRef) {
      query = query.where("providerId", "==", this.providerRef.id)
    }

    let reuestedquerySnapshot = await query.get()
    // return querySnapshot.empty;
    if (reuestedquerySnapshot.docs.length > 0) {
      console.log(reuestedquerySnapshot.docs[0].data())
    }
    if (this.settings.clinicNumberOfRooms)
      return (bookedQuerySnapshot.docs.length + reuestedquerySnapshot.docs.length) < this.settings.clinicNumberOfRooms;
    else
      return bookedQuerySnapshot.empty || reuestedquerySnapshot.empty;


  }



  getCalendarSettings(acc_slug: string, calendar_slug: string) {
    this.acc_slug = acc_slug;
    this.calendar_slug = calendar_slug;
    return firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", acc_slug)
      .get()
      .then((accountSnapshot) => {
        //this.acc_details = accountSnapshot.docs[0].data();
        let acc_id = accountSnapshot.docs[0].id;
        this.acc_ref = accountSnapshot.docs[0].ref;
        this.accDetails = accountSnapshot.docs[0].data();
        this.acc_name = this.accDetails.name;
        // this.acc_name = accountSnapshot.docs[0].data().name;
        return firebase
          .firestore()
          .collection("accounts")
          .doc(acc_id)
          .collection("calendars")
          .where("slug", "==", this.calendar_slug)
          .get();
      });
  }

  fetchAppointmentDetails(acc_slug: string, appt_id: string) {
    this.acc_slug = acc_slug;
    let app = firebase
      .firestore()
      .collection("accounts")
      .doc(this.acc_slug)
      .collection("appointments")
      .doc(appt_id);
  }

  checkSubAccountExist(subAccountsArray, newSubAccountRef) {
    let subAccountExist = false;
    subAccountsArray.forEach(subAccount => {
      if (subAccount.id == newSubAccountRef.id) {
        subAccountExist = true;
      }
    });
    return subAccountExist;
  }

  async saveContact() {
    this.createdOn = moment();
    let contact = {
      //"name": `${formData.first_name} ${formData.last_name}`,
      first_name: this.formData.first_name,
      last_name: this.formData.last_name,
      phone: this.formData.phone_no,
      email: this.formData.email,
      countryCode: this.formData.countryCode,
      full_phone: this.formData.countryCode + this.formData.phone_no,

    };
    console.log(this.formData.email);
    if (this.formData.birthDate != undefined && this.formData.birthDate != "") {
      contact['dob'] = moment(this.formData.birthDate).format('MMMM, DD')
      contact['birthDate'] = this.formData.birthDate
    }
    //return firebase.firestore().collection('accounts').doc('7XZFnNuPDWuDqwOzc8cu').collection('contacts')
    if (this.contactRef) {
      let contactSnap = await this.contactRef.get();
      let contactData = contactSnap.data();
      contact['subAccountRefArray'] = contactData.subAccountRefArray ? contactData.subAccountRefArray : []
      if (this.settings.subAccountRef && !this.checkSubAccountExist(contact['subAccountRefArray'], this.settings.subAccountRef)) {
        contact['subAccountRefArray'].push(this.settings.subAccountRef)
      }
      await this.contactRef.update(contact);
      return this.contactRef;
    }

    let query = this.acc_ref.collection("contacts").where("full_phone", "==", contact.full_phone)
    let conatctQuerySnapshot = await query.get()
    if (conatctQuerySnapshot.docs.length == 0) {
      query = this.acc_ref.collection("contacts").where("email", "==", contact.email)
      conatctQuerySnapshot = await query.get()
    }

    if (conatctQuerySnapshot.docs.length == 0) {
      contact["created_on"] = this.createdOn.toDate();
      contact["source"] = this.source;
      contact["send_text_reminders"] = this.formData.send_text_reminders? this.formData.send_text_reminders : false;
      contact["active"] = true;
      if (this.settings.subAccountRef) {
        contact['subAccountRefArray'] = [this.settings.subAccountRef]
      }
      if (this.providerRef !== undefined) {
        contact["providerReference"] = this.providerRef;
      }
      let newContactSnap = await this.acc_ref.collection("contacts").add(contact)
      return newContactSnap
    }
    else {
      let contactInfo = conatctQuerySnapshot.docs[0].data();
      if (contactInfo.providerReference === undefined && this.providerRef !== undefined) {
        contact["providerReference"] = this.providerRef;
      }
      if (this.formData.send_text_reminders) {
        contact["send_text_reminders"] = this.formData.send_text_reminders
      }
      contact["updated_on"] = moment().toDate();
      contact['subAccountRefArray'] = contactInfo.subAccountRefArray ? contactInfo.subAccountRefArray : []
      if (this.settings.subAccountRef && !this.checkSubAccountExist(contact['subAccountRefArray'], this.settings.subAccountRef)) {
        contact['subAccountRefArray'].push(this.settings.subAccountRef)
      }
      await conatctQuerySnapshot.docs[0].ref.update(contact);
      return conatctQuerySnapshot.docs[0].ref;
    }
  }

  setProviderDetails(providerId) {
    let providerObj = this.providersGroupData.filter(
      (doc) => doc.docId === providerId
    );
    this.providersName = providerObj[0]["name"];
    this.providerRef = providerObj[0]["providerRef"];
    return providerObj;
  }

  getProvidersName(provRef) {
    return provRef.get().then((provSnap) => {
      let providerData = provSnap.data();
      this.providersName = providerData["name"];
      this.providerRef = provSnap.ref;
      return this.providersName;
    });
  }

  async saveAppointment(apptStatus) {
    if (this.providersName === undefined) {
      if (this.providerRef !== undefined) {
        this.getProvidersName(this.providerRef);
      }
    }
    let contactRef = null;
    if (!this.accDetails.requestedAppointment) {
      contactRef = await this.saveContact();
      this.contactRef = contactRef;
      this.patientContactId = contactRef.id
    }


    this.bookedOn = moment();
    let appointment = {
      contact_info: {
        first_name: this.formData.first_name,
        last_name: this.formData.last_name,
        email: this.formData.email,
        phone: this.formData.phone_no,
        send_text_reminders: this.formData.send_text_reminders
      },
      contact: contactRef,
      date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.selectedSlot.start} - ${this.selectedSlot.end}`,
      appt_start: moment
        .tz(
          `${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedSlot.start
          }`,
          "YYYY-MM-DD h:mm a",
          this.timezone
        )
        .toDate(),
      appt_end: moment
        .tz(
          `${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedSlot.end
          }`,
          "YYYY-MM-DD h:mm a",
          this.timezone
        )
        .toDate(),
      booked_on: this.bookedOn.toDate(),
      source: this.source,
      category: this.category,
      status: apptStatus,
      services: this.formData.services ? this.formData.services : null,
      location: this.settings.location.name,
      notes: "",
      new_patient: this.formData.patientType,
      value: "",
      //"locationRefence":;
      calendarReference: this.calendarRef,
      // "providerReference": this.providerRef,
      // "provider_name":this.providersName,
      user_timezone: moment.tz.guess(),
      type: this.formData.type,
      isEmergency: this.formData.isEmergency,
      birthDate: this.formData.birthDate,
      deviceInfo: this.deviceService.getDeviceInfo()
    };


    appointment["gclid"] = this.gclid ? this.gclid : null
    appointment["pageUrl"] = this.pageUrl ? this.pageUrl : null
    appointment["referer"] = this.referer ? this.referer : null

    if (this.providerRef !== undefined) {
      appointment["providerReference"] = this.providerRef;
      appointment["provider_name"] = this.providersName;
    }

    if ("form_fields" in this.settings) {
      if (this.settings.form_fields.address !== undefined) {
        if (this.settings.form_fields.address === true) {
          this.address = {
            street:
              this.formData.street !== undefined ? this.formData.street : "",
            city: this.formData.city !== undefined ? this.formData.city : "",
            state:
              this.formData.state !== undefined ? this.formData.state : "",
          };
          if (this.formData.postalCode) {
            this.address['postalCode'] = this.formData.postalCode;
          }
          appointment["contact_info"]["address"] = this.address;
        }
      }
      if (this.settings.form_fields.message !== undefined) {
        if (this.settings.form_fields.message.show === true) {
          appointment["message"] = this.formData.message == undefined ? "" : this.formData.message;
        }
      }
    }

    if (this.accDetails.requestedAppointment && this.providerRef) {
      appointment["providerId"] = this.providerRef.id;
    }

    if (this.settings.subAccountRef) {
      appointment['subAccountRef'] = this.settings.subAccountRef
    }

    if (this.leadPipelineId) {
      appointment['leadPipelineId'] = this.leadPipelineId
    }
    if (this.formRespId) {
      appointment['formRespId'] = this.formRespId
    }
    let apptCollection = this.accDetails.requestedAppointment ? 'requested_appointment' : 'appointments'

    return this.acc_ref
      .collection(apptCollection)
      .add(appointment)
      .then((apptRef) => {
        return { contactRef: contactRef, apptRef: apptRef };
      });
  }

  saveAppointmentReference(reference: any) {
    if (this.multipleProviders == true) {
      this.url = `/reschedule-provider-groups/${this.acc_slug}/${this.provider_slug}`;
    } else if (this.providerLocations == true) {
      this.url = `/reschedule-provider-locations/${this.acc_slug}/${this.providerId}`;
    } else if (this.multipleLocations == true) {
      this.url = `/reschedule-calendar-groups/${this.acc_slug}/${this.group_slug}`;
    } else {
      this.url = `/reschedule-calendar/${this.acc_slug}/${this.calendar_slug}`;
    }
    let appointment_reference = {
      appt_ref: reference,
      redirectUrl: this.url,
    };
    return firebase
      .firestore()
      .collection("reschedules")
      .add(appointment_reference)
      .then((docId) => {
        this.rescheduleDoc = docId.id;
      });
  }
  saveRescheduleDoc(appt_ref: any) {
    console.log("saveReschedule doc");
    appt_ref.update({ reschedule_doc: this.rescheduleDoc });
  }

  async getNumberStatus(phone: string, country_code: string) {
    let phone_no = country_code + phone;
    console.log(phone_no);
    return await firebase.firestore().collection('phoneValidationDetails').where("phoneNumber", "==", phone_no).get();
  }
  lookupPhoneNumber(phone: any, countryCode: any, country: string) {
    let phoneInfo = {
      "phone": phone,
      "countryCode": countryCode,
      "country": country,
      "accSlug": this.acc_slug,
    }
    return this.http.post(`${environment.cloudFunctionDomain}/lookupPhoneNumber`, phoneInfo);
    // return this.http.post(`http://localhost:5001/mktgbot-181017/us-central1/lookupPhoneNumber`, phoneInfo);
  }

  getTwilioCredentials() {
    return this.acc_ref.collection('linked_accounts').doc('Twilio_accounts').get().then(data => {
      console.log(data);
      let twilioDetails = data.data();
      console.log(twilioDetails);
      return twilioDetails;
    });
  }

  generateOtp() {
    let otp = Math.floor(100000 + Math.random() * 900000);
    console.log(otp);
    return otp;
  }

  sendEmailVerificationOtp(email, otp) {
    let body = `<p>Hi,</p>
          <br><p><b>${otp}</b> is One time password (OTP) for your ${this.acc_name}. Please do not share this.If not requested, please inform us.</p>
          <br>`
    firebase.functions().httpsCallable('sendEmail')({
      "to_email": email,
      "subject": "OTP for signup",
      "body": body,
      "from": "appointments@nhansmedia.com"
    }).then(resp => {
      console.log(resp);
    },
      err => {
        console.log(err);
      })
  }

  sendSms(messageObj: any) {
    let smsDetails = {
      to: messageObj.to,
      sourceMsg: messageObj.msg,
      from: messageObj.from,
      acc_sid: messageObj.acc_sid,
      auth_token: messageObj.auth_token,
      service_sid: messageObj.service_sid,
      acc_id: this.acc_ref.id,
      contact_ids: [],
      sourceLanguage: 'en',
      targetLanguage: 'en'
    }
    // firebase.initializeApp(environment.mktg_firebase);
    this.http.post(`https://us-central1-${environment.mktg_firebase.projectId}.cloudfunctions.net/sendSmsV3`, smsDetails).subscribe(resp => {
      console.log(resp);
      console.log("sms Send to user");
    });
  }

  getVerificationStatus(status: string) {
    if (status == 'verified') {
      console.log("Status is verified");
      // this.confirmAppointment();
    }
  }

  confirmAppointment() {
    console.log(this.formData);
    let formData = this.formData;
    let taskEvent = {
      title: "Appointment booked",
      type: "APPOINTMENT_BOOKED",
      description: "Sent email to patient",
      createdOn: moment().toDate(),
      contactRef: null,
      sendType: "EMAIL"
    }
    if (this.accDetails.requestedAppointment) {
      this.confirmRequestedAppointmentValidity().then((valid) => {
        if (valid) {
          this.saveAppointment('requested').then(
            (docRef) => {
              let appointmentRef = docRef.apptRef;
              this.saveAppointmentReference(appointmentRef).then(async (data) => {
                this.saveRescheduleDoc(appointmentRef);
                this.setSelectedServices(formData.services);
                let resp = await forkJoin({
                  user: this.sendRequestedAppointmentEmailToUser(),
                  owner: this.sendRequestedAppointmentEmailToOwner(),
                  sms: this.sendSmsToUser('undefined')
                }).toPromise()
                // if (this.formData.send_text_reminders == true) {
                //   if (this.accDetails.sendAppointmentSms !== undefined && this.accDetails.sendAppointmentSms == true) {
                //     // sendSma to user function take contat document id as a paameter but in requested appointment we don't create contact so
                //     // we passe undefined string here it is only purpose passing parameter to function not any use
                //     this.sendSmsToUser('undefined');
                //   }
                // }
                window.parent.postMessage(JSON.stringify({ event: "tracking-trigger-event", source: this.getSource(), category: this.getCategory(), }), "*");
                window.parent.postMessage(JSON.stringify({ event: "interactive-appointment-booked" }), "*");

                try {
                  let leadUpdateObj = {
                    requestedAppointmentRef: appointmentRef,
                    stage: 'REQUESTED_APPOINTMENT'
                  }
                  if (this.settings.subAccountRef) {
                    leadUpdateObj['subAccountRef'] = this.settings.subAccountRef
                  }

                  if (this.leadPipelineId) {
                    let leadPipelineSnap = await this.acc_ref.collection('leadPipeline').doc(this.leadPipelineId).get()
                    if (leadPipelineSnap.exists) {

                      await leadPipelineSnap.ref.update(leadUpdateObj);
                      await appointmentRef.update({ leadPipelineRef: leadPipelineSnap.ref });
                      let apptEvent = {
                        title: "Appointment Requested",
                        type: "TASK",
                        status: "REQUESTED_APPOINTMENT",
                        description: `The appointment on ${this.selectedDate.format("MMMM D, YYYY")} at ${this.selectedSlot.start} - ${this.selectedSlot.end} has been requested `,
                        createdOn: moment().toDate(),
                        leadPipelineRef: leadPipelineSnap.ref
                      }
                      let leadData = leadPipelineSnap.data();
                      if (leadData.contactRef) {
                        apptEvent['contactRef'] = leadData.contactRef;
                      }
                      await this.acc_ref.collection('activity').add(apptEvent);
                      window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
                      console.log("Appointment added!");
                      this.addFollowUpTask(this.acc_ref.id, leadPipelineSnap.id);
                    }
                  }
                  else if (this.formRespId) {
                    let leadPipelineSnap = await this.acc_ref.collection('leadPipeline').where('formResponseRef', '==', this.acc_ref.collection('form_response').doc(this.formRespId)).get()
                    if (leadPipelineSnap.docs.length > 0) {

                      await leadPipelineSnap.docs[0].ref.update(leadUpdateObj);
                      await appointmentRef.update({ leadPipelineRef: leadPipelineSnap.docs[0].ref });

                      let apptEvent = {
                        title: "Appointment Requested",
                        type: "TASK",
                        status: "REQUESTED_APPOINTMENT",
                        description: `The appointment on ${this.selectedDate.format("MMMM D, YYYY")} at ${this.selectedSlot.start} - ${this.selectedSlot.end} has been requested `,
                        createdOn: moment().toDate(),
                        leadPipelineRef: leadPipelineSnap.docs[0].ref
                      }
                      let leadData = leadPipelineSnap.docs[0].data();
                      if (leadData.contactRef) {
                        apptEvent['contactRef'] = leadData.contactRef;
                      }
                      await this.acc_ref.collection('activity').add(apptEvent);
                      window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
                      console.log("Appointment added!");
                      this.addFollowUpTask(this.acc_ref.id, leadPipelineSnap.docs[0].id);
                    }
                  } else {
                    let leadPipelineData = {
                      firstName: this.formData.first_name,
                      lastName: this.formData.last_name,
                      email: this.formData.email,
                      phone: this.formData.phone_no,
                      createdOn: moment().toDate(),
                      stage: "REQUESTED_APPOINTMENT",
                      category: this.category ? this.category : null,
                      source: this.source ? this.source : null,
                      followUpTaskRef: null,
                      formName: null,
                      requestedAppointmentRef: appointmentRef,
                      formRef: null,
                      formResponseRef: null,
                      pageUrl: this.pageUrl ? this.pageUrl : null,
                      redirectUrl: null,
                      type: "Ads",
                      referer: this.referer ? this.referer : null,
                      gclid: this.gclid ? this.gclid : null,
                      deviceInfo: this.deviceService.getDeviceInfo()
                    }
                    if (this.settings.subAccountRef) {
                      leadPipelineData['subAccountRef'] = this.settings.subAccountRef
                    }
                    let leadDocSnapShot = await this.acc_ref.collection('leadPipeline').add(leadPipelineData)
                    await appointmentRef.update({ leadPipelineRef: leadDocSnapShot });
                    let apptEvent = {
                      title: "Appointment Requested",
                      type: "TASK",
                      status: "REQUESTED_APPOINTMENT",
                      description: `The appointment on ${this.selectedDate.format("MMMM D, YYYY")} at ${this.selectedSlot.start} - ${this.selectedSlot.end} has been requested `,
                      createdOn: moment().toDate(),
                      leadPipelineRef: leadDocSnapShot
                    }
                    await this.acc_ref.collection('activity').add(apptEvent);
                    //this.addFollowUpTask(this.acc_ref.id, leadDocSnapShot.id);
                    window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
                  }
                } catch (e) {
                  console.log(e);
                }
                this.router.navigate(["/appointment-status"], {
                  queryParams: { status: "confirmed" },
                });
              });
            },
            (error) => {
              this.router.navigate(["/appointment-status"], {
                queryParams: { status: "unconfirmed", errorIn: "saving" },
              });
              console.error(error);
            }
          );
        } else {
          this.router.navigate(["/appointment-status"], {
            queryParams: { status: "unconfirmed", errorIn: "confirming" },
          });
          console.error(
            "The selected slot for selected date has now become unavailable."
          );
        }
      });

    } else {
      this.confirmAppointmentValidity().then((valid) => {
        if (valid) {
          this.saveAppointment("booked").then(
            (docRef) => {
              let appointmentRef = docRef.apptRef;
              console.log(docRef);
              this.saveAppointmentReference(appointmentRef).then(async (data) => {
                console.log(data);
                let appt_id = appointmentRef.id;
                this.saveRescheduleDoc(appointmentRef);
                this.setSelectedServices(formData.services);
                let resp = await forkJoin({
                  owner: this.sendEmailToOwner(),
                  user: this.sendEmailToUser(),
                  sms: this.sendSmsToUser(docRef.contactRef.id)
                }).toPromise()
                console.log(this.formData.send_text_reminders);
                console.log(this.accDetails.sendAppointmentSms);
                // if (this.formData.send_text_reminders == true) {
                //   if (this.accDetails.sendAppointmentSms !== undefined && this.accDetails.sendAppointmentSms == true) {
                //     this.sendSmsToUser(docRef.contactRef.id);
                //   }
                // }

                taskEvent.contactRef = this.contactRef;
                taskEvent.description = "Sent email to patient - Appointment booked on " + this.selectedDate.format("MMMM D, YYYY") + " at " + this.selectedSlot.start + " - " + this.selectedSlot.end;

                await this.acc_ref.collection('activity').add(taskEvent);
                window.parent.postMessage(JSON.stringify({ event: "tracking-trigger-event", source: this.getSource(), category: this.getCategory(), }), "*");
                window.parent.postMessage(JSON.stringify({ event: "interactive-appointment-booked" }), "*");

                // update status
                try {
                  let leadUpdateObj = {
                    appointmentRef: appointmentRef,
                    stage: 'CONSULTATION_BOOKED'
                  }
                  if (this.settings.subAccountRef) {
                    leadUpdateObj['subAccountRef'] = this.settings.subAccountRef
                  }

                  if (this.leadPipelineId) {
                    let leadPipelineSnap = await this.acc_ref.collection('leadPipeline').doc(this.leadPipelineId).get()
                    if (leadPipelineSnap.exists) {
                      await leadPipelineSnap.ref.update(leadUpdateObj);
                      await appointmentRef.update({ leadPipelineRef: leadPipelineSnap.ref });
                      window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
                      console.log("Appointment added!");
                      this.addFollowUpTask(this.acc_ref.id, this.leadPipelineId);
                    }
                  }
                  else if (this.formRespId) {
                    let leadPipelineSnap = await this.acc_ref.collection('leadPipeline').where('formResponseRef', '==', this.acc_ref.collection('form_response').doc(this.formRespId)).get()
                    if (leadPipelineSnap.docs.length > 0) {
                      leadPipelineSnap.docs[0].ref.update(leadUpdateObj);
                      await appointmentRef.update({ leadPipelineRef: leadPipelineSnap.docs[0].ref });
                      window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
                      console.log("Appointment added!");
                      this.addFollowUpTask(this.acc_ref.id, leadPipelineSnap.docs[0].id);
                    }
                  } else {
                    let leadPipelineData = {
                      firstName: this.formData.first_name,
                      lastName: this.formData.last_name,
                      email: this.formData.email,
                      phone: this.formData.phone_no,
                      contactRef: this.contactRef,
                      createdOn: moment().toDate(),
                      stage: "CONSULTATION_BOOKED",
                      category: this.category ? this.category : null,
                      source: this.source ? this.source : null,
                      followUpTaskRef: null,
                      formName: null,
                      formRef: null,
                      appointmentRef: appointmentRef,
                      formResponseRef: null,
                      pageUrl: this.pageUrl ? this.pageUrl : null,
                      redirectUrl: null,
                      type: "Ads",
                      referer: this.referer ? this.referer : null,
                      gclid: this.gclid ? this.gclid : null,
                      deviceInfo: this.deviceService.getDeviceInfo()
                    }
                    if (this.settings.subAccountRef) {
                      leadPipelineData['subAccountRef'] = this.settings.subAccountRef
                    }
                    let leadDocSnapShot = await this.acc_ref.collection('leadPipeline').add(leadPipelineData)
                    await appointmentRef.update({ leadPipelineRef: leadDocSnapShot });
                    // this.addFollowUpTask(this.acc_ref.id, leadDocSnapShot.id);
                    window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
                  }
                } catch (e) {
                  console.log(e);
                }
                this.router.navigate(["/appointment-status"], {
                  queryParams: { status: "confirmed" },
                });
              });
            },
            (error) => {
              this.router.navigate(["/appointment-status"], {
                queryParams: { status: "unconfirmed", errorIn: "saving" },
              });
              console.error(error);
            }
          );
        } else {
          this.router.navigate(["/appointment-status"], {
            queryParams: { status: "unconfirmed", errorIn: "confirming" },
          });
          console.error(
            "The selected slot for selected date has now become unavailable."
          );
        }
      });
    }
  }

  async addFollowUpTask(accId, leadPipelineId) {
    let reqData = {
      "accountId": accId,
      "leadPipelineId": leadPipelineId,
    };
    let leadDocSnapShot = await this.acc_ref.collection('leadPipeline').doc(leadPipelineId).get();
    let leadPipelineData = leadDocSnapShot.data();
    if (leadPipelineData.followUpTaskRef) {
      console.log("follow up task set ");
      this.http.post(`${environment.cloudFunctionDomain}/followUpReminders`, reqData)
        .subscribe(
          response => {
            // console.log("Contact successfully added to HubSpot!");
          },
          error => {
            console.error(error);
          }
        );
    }
    else {
      console.log("no follow up task set in this form");
    }
  }

  //save appointment before starting payment.
  saveAppointmentForPayment(paymentInfo) {
    // let formData = this.appointmentService.formData;
    return new Promise((resolve, reject) => {
      this.confirmAppointmentValidity().then((valid) => {
        if (valid) {
          this.saveAppointment("booked").then(
            (appointmentInfo) => {
              let appointmentRef = appointmentInfo.apptRef;
              if (paymentInfo.paymentMethod == 'paypal') {
                let paymentDetails = {
                  orderId: paymentInfo.paymentId,
                  method: 'paypal'
                }
                appointmentRef.update({ paymentDetails: paymentDetails });
              }
              else if (paymentInfo.paymentMethod == 'paytm') {
                let paymentDetails = {
                  orderId: appointmentRef.id,
                  method: 'paytm'
                }
                appointmentRef.update({ paymentDetails: paymentDetails });
              }
              this.saveAppointmentReference(appointmentRef).then((data) => {
                // let appt_id = appointmentRef.id ;
                this.saveRescheduleDoc(appointmentRef);
                this.setSelectedServices(this.formData.services);

                // this.paytmService.getChecksum(appointmentInfo.apptRef.id,appointmentInfo.contactRef.id)
                console.log("Appointment added!");
                // return appointmentInfo;
                resolve(appointmentInfo);
              });
            },
            (error) => {
              console.error(error);
              this.router.navigate(["/appointment-status"], {
                queryParams: { status: "unconfirmed", errorIn: "saving" },
              });
              resolve({ status: "error", errorIn: "confirming" });
            }
          );
        } else {
          this.router.navigate(["/appointment-status"], {
            queryParams: { status: "unconfirmed", errorIn: "confirming" },
          });
          console.error(
            "The selected slot for selected date has now become unavailable."
          );
          resolve({ status: "error", errorIn: "confirming" });
        }
      });
    });
  }

  createAppoitntmentProcessingTask(data) {
    return firebase
      .functions()
      .httpsCallable("createAppoitntmentProcessingTask")(data)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }

  //after payment confirmation update appointment with payment details and call to send sms, send email's to user and owner
  async updatePaymentDetailsInAppointment(appointmentInfo, paymentInfo, dataLayer?) {
    return new Promise((resolve, reject) => {
      let apptSnap = appointmentInfo.apptRef.update({ 'paymentDetails': paymentInfo });
      window["dataLayer"].push({ event: "interactive-appointment-booked" });
      window["dataLayer"].push({ event: "tracking-trigger-event", source: this.getSource(), category: this.getCategory(), });
      console.warn(window["dataLayer"])
      this.sendEmailToOwner();
      this.sendEmailToUser();
      this.addInLeadPipeline(appointmentInfo);

      if (this.accDetails.sendAppointmentSms !== undefined && this.accDetails.sendAppointmentSms == true) {
        this.sendSmsToUser(appointmentInfo.contactRef.id);
      }
      resolve(apptSnap);
    });
  }


  async addInLeadPipeline(appointmentInfo) {
    let leadPipelineData = {
      firstName: appointmentInfo.contact_info?.first_name ? appointmentInfo.contact_info?.first_name : '',
      lastName: appointmentInfo.contact_info?.last_name ? appointmentInfo.contact_info?.last_name : '',
      email: appointmentInfo.contact_info?.email ? appointmentInfo.contact_info?.email : '',
      phone: appointmentInfo.contact_info?.phone ? appointmentInfo.contact_info?.phone : '',
      contactRef: appointmentInfo.contact ? appointmentInfo.contact : null,
      createdOn: moment().toDate(),
      stage: "CONSULTATION_BOOKED",
      category: appointmentInfo.category ? appointmentInfo.category : null,
      source: appointmentInfo.source ? appointmentInfo.source : null,
      followUpTaskRef: null,
      formName: null,
      formRef: null,
      appointmentRef: appointmentInfo.apptRef,
      formResponseRef: null,
      pageUrl: appointmentInfo.pageUrl ? appointmentInfo.pageUrl : null,
      redirectUrl: null,
      type: "Ads",
      referer: appointmentInfo.referer ? appointmentInfo.referer : null,
      gclid: appointmentInfo.gclid ? appointmentInfo.gclid : null,
      deviceInfo: this.deviceService.getDeviceInfo(),
    }
    if (appointmentInfo.subAccountRef) {
      leadPipelineData['subAccountRef'] = appointmentInfo.subAccountRef
    }
    let taskEvent = {
      title: "Appointment booked",
      type: "APPOINTMENT_BOOKED",
      description: "Sent email to patient",
      createdOn: moment().toDate(),
      contactRef: null,
      sendType: "EMAIL"
    }
    try {
      taskEvent.contactRef = appointmentInfo.contact;
      taskEvent.description = "Sent email to patient - Appointment booked on " + this.selectedDate.format("MMMM D, YYYY") + " at " + this.selectedSlot.start + " - " + this.selectedSlot.end;
      await this.acc_ref.collection('activity').add(taskEvent);
    } catch (e) {
      console.log(e);
    }

    try {
      if (appointmentInfo.leadPipelineId) {
        let leadPipelineSnap = await this.acc_ref.collection('leadPipeline').doc(this.leadPipelineId).get()
        if (leadPipelineSnap.exists) {
          let obj = {
            appointmentRef: appointmentInfo.appointmentRef,
            stage: 'CONSULTATION_BOOKED'
          }
          if (appointmentInfo.subAccountRef) {
            obj['subAccountRef'] = appointmentInfo.subAccountRef
          }
          await leadPipelineSnap.ref.update(obj);
          await appointmentInfo.appointmentRef.update({ leadPipelineRef: leadPipelineSnap.ref });
          window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
          console.log("Appointment added!");
          this.addFollowUpTask(this.acc_ref.id, this.leadPipelineId);
        }
      }
      else if (appointmentInfo.formRespId) {
        let leadPipelineSnap = await this.acc_ref.collection('leadPipeline').where('formResponseRef', '==', this.acc_ref.collection('form_response').doc(this.formRespId)).get()
        if (leadPipelineSnap.docs.length > 0) {
          let obj = {
            appointmentRef: appointmentInfo.appointmentRef,
            stage: 'CONSULTATION_BOOKED'
          }
          if (appointmentInfo.subAccountRef) {
            obj['subAccountRef'] = appointmentInfo.subAccountRef
          }
          leadPipelineSnap.docs[0].ref.update(obj);
          await appointmentInfo.appointmentRef.update({ leadPipelineRef: leadPipelineSnap.docs[0].ref });
          window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
          console.log("Appointment added!");
          this.addFollowUpTask(this.acc_ref.id, leadPipelineSnap.docs[0].id);
        }
      } else {
        let leadDocSnapShot = await this.acc_ref.collection('leadPipeline').add(leadPipelineData)
        await appointmentInfo.appointmentRef.update({ leadPipelineRef: leadDocSnapShot });
        window.parent.postMessage(JSON.stringify({ event: "appointment-booked" }), "*");
      }
    } catch (e) {
      console.log(e);
    }


  }



  async getAppointmentDetails(accSlug, transactionID, cal_slug) {
    let acc = await firebase.firestore().collection("accounts").where("account_slug", "==", accSlug).get();
    this.accDetails = acc.docs[0].data();
    this.acc_ref = acc.docs[0].ref;
    this.acc_name = this.accDetails.name;



    let cal = await firebase.firestore().collection(`accounts/${this.acc_ref.id}/calendars`).where("slug", "==", cal_slug).get();
    this.settings = cal.docs[0].data();
    this.calendarRef = cal.docs[0].ref;
    this.calendar_slug = this.settings.slug;

    if (this.settings.subAccountRef) {
      let subAcountSnapShot = await this.settings.subAccountRef.get()
      this.acc_name = subAcountSnapShot.data()['name']
    }

    this.acc_slug = accSlug;
    let appt = await acc.docs[0].ref.collection("appointments").where("paymentDetails.orderId", "==", transactionID).get();
    if (appt.empty) {
      console.log("Appointment not found redirecting to payment status component")
      return null;
    }
    let apptData = appt.docs[0].data();
    this.rescheduleDoc = apptData.reschedule_doc;
    let calInfo = await apptData.calendarReference.get();
    this.settings = calInfo.data();
    this.calendar_slug = this.settings.slug;
    this.timezone = this.settings["timezone"];
    this.bookedOn = moment(apptData.booked_on.toDate());
    let contactInfo = await apptData.contact.get();

    let contactData = contactInfo.data();
    if (contactData.providerReference) {
      delete contactData.providerReference;
    }
    if (contactData.created_on) {
      delete contactData.created_on;
    }
    if (contactData.lastMessages) {
      delete contactData.lastMessages;
    }
    if (contactData.updated_on) {
      delete contactData.updated_on;
    }
    this.formData = contactData;
    this.formData.phone_no = this.formData.phone;
    this.formData.patientType = apptData.new_patient;
    this.formData.services = apptData.services;

    if (apptData.contact_info.address !== undefined) {
      this.formData['street'] = apptData.contact_info.address.street !== undefined ? apptData.contact_info.address.street : "";
      this.formData['city'] = apptData.contact_info.address.city !== undefined ? apptData.contact_info.address.city : "";
      this.formData['state'] = apptData.contact_info.address.state !== undefined ? apptData.contact_info.address.state : "";
    }
    this.services = apptData.services;
    this.selectedDate = moment(apptData.appt_start.toDate());
    this.selectedSlot = {
      start: apptData.slot.split("-")[0].trim(),
      end: apptData.slot.split("-")[1].trim()
    };
    this.source = apptData.source;
    this.category = apptData.category;
    if (apptData.providerReference !== undefined) {
      this.providerRef = apptData.providerReference;
      let provider = await this.providerRef.get();
      this.providersName = provider.data().name;
    }
    let appointmentInfo = {

      apptRef: appt.docs[0].ref,
      contactRef: apptData.contact,
      ...apptData
    };
    return appointmentInfo  //appt.docs[0];
  }

  async sendEmailToOwner() {
    // console.log(form_data);
    console.log("In send Email to owner function ");

    let to_email = this.settings.owner_email;

    // this.formData['patientType']= this.formData['patientType'] === 'Yes' ? 'New' : 'Old';
    let appointmentInfo = {
      form_data: this.formData,
      date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.selectedSlot.start} - ${this.selectedSlot.end} ${moment
        .tz(this.timezone)
        .format("z")}`,
      to_email: to_email,
      source: this.source,
      booked_on: this.bookedOn.tz(this.timezone).format("MMMM D, Y h:mm A z"),
      services: this.formData.services ? this.formData.services : "",
      location: this.settings.location.name,
      terms_conditions: this.settings.terms_conditions,
      terms_conditions_info: this.settings.terms_conditions_info,
      links_msg: `<strong>Click here to </strong><a href="${environment.clientURL}/reschedule/${this.rescheduleDoc}">reschedule</a>
        <strong> or </strong><a href="${environment.clientURL}/cancel/${this.rescheduleDoc}">cancel</a><strong> this appointment.</strong>`,
    };
    if ("form_fields" in this.settings) {
      if (this.settings.form_fields.message.show === true) {
        appointmentInfo["title"] =
          this.settings.form_fields.message.title !== ""
            ? this.settings.form_fields.message.title
            : "Message";
        appointmentInfo.form_data.message = this.settings.form_fields.message.message ? this.settings.form_fields.message.message : ""
      }
    }
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }
    console.log(appointmentInfo);
    let resp = await this.http.post(`${environment.appt_firebase.cloudFunctionDomain}/sendAppointmentEmailToOwner`, appointmentInfo, { headers: new HttpHeaders({ "Content-Type": "text/plain" }) }).toPromise()
    console.log(resp);
    console.log("email Send to owner");
  }

  //send appointment confirmation sms to user for sendAppointmentSms subscribed accounts only
  async sendSmsToUser(contactId) {
    if (this.formData.send_text_reminders == true) {
      if (this.accDetails.sendAppointmentSms !== undefined && this.accDetails.sendAppointmentSms == true) {
        let date = this.selectedDate.format("MMMM D, YYYY");
        let time = `${this.convertToLocalTime(
          this.selectedSlot.start
        )} ${this.getLocalTimezoneAbbr()}`;
        let twillioCredentials = null
        if (this.settings.subAccountRef) {
          let twillioAccountSnapshot = await this.acc_ref.collection('linked_accounts').where("type", "==", "twilio").where('subAccountRef', '==', this.settings.subAccountRef).get();
          if (twillioAccountSnapshot.docs.length > 0) {
            twillioCredentials = twillioAccountSnapshot.docs[0].data();
          }
        }

        if (twillioCredentials === null) {
          let twillioAccountSnapshot = await this.acc_ref.collection('linked_accounts').doc('Twilio_accounts').get()
          twillioCredentials = twillioAccountSnapshot.data();
          twillioCredentials.twilio_from = this.accDetails.twilio_from;
        }
        if (twillioCredentials) {
          let twilioDetails = twillioCredentials
          let status = this.accDetails.requestedAppointment ? 'requested' : 'confirmed';
          let msg = `${this.acc_name}: ${this.formData.first_name}, your appointment on ${date} at ${time} is ${status}.\nLocation: ${this.settings.location.address}\nPhone: ${this.settings.location.phone}\nReply STOP to unsubscribe`;
          let targetLang = 'en';
          let snapshot = await this.acc_ref.collection('messages').doc(this.formData.countryCode + this.formData.phone_no).get();
          if (snapshot.exists) {
            let data = snapshot.data();
            targetLang = data ? data.targetLanguage : 'en';
          }
          let smsDetails = {
            to: [this.formData.countryCode + this.formData.phone_no],
            sourceMsg: msg,
            from: twillioCredentials.twilio_from,
            acc_sid: twilioDetails.account_sid,
            auth_token: twilioDetails.auth_token,
            service_sid: twilioDetails.notify_service_sid,
            acc_id: this.acc_ref.id,
            contact_ids: this.accDetails.requestedAppointment ? [] : [contactId],
            sourceLanguage: 'en',
            targetLanguage: targetLang
          }
          // firebase.initializeApp(environment.mktg_firebase);
          let resp = await this.http.post(`https://us-central1-${environment.mktg_firebase.projectId}.cloudfunctions.net/sendSmsV3`, smsDetails).toPromise()
          console.log(resp);
          console.log("sms Send to user");
        }
      }
    }
  }

  async sendEmailToUser() {
    //  let reschedule_link = `<p>To reschedule the appointment, click here: <a href="http://localhost:4200/calendar/${this.acc_slug}/${this.calendar_slug}?appt_id = id">Reschedule</a></p>`
    let further_info = "";
    let appointment_with = this.acc_name;
    let message = ''
    if (this.formData.type == "Virtual Visit") {
      message = this.settings.virtualEmailMessege
    }
    else {
      message = this.settings.message === undefined ? "" : this.settings.message;
    }

    if (this.settings.sendPatientForm && this.formData.patientType == "Yes") {
      let patient_forms_id_array = []
      let shortUrl = "";
      let result: any = {}
      await this.acc_ref
        .collection("patient_forms").get().then(async (querySnap) => {
          for (let i = 0; i < querySnap.docs.length; i++) {
            patient_forms_id_array.push(querySnap.docs[0].id)
          }
        });
      let data =
      {
        "contacts": [
          this.patientContactId
        ],
        "forms": patient_forms_id_array,
        "url": `${environment.patientFormUrl}patient-form/`,
        "task": "send",
        "acc_id": this.acc_ref.id,
        "sendPatientFormsUrlForAppointment": true
      }
      await firebase
        .functions()
        .httpsCallable("sendPatientForms")(data)
        .then(resp => {
          console.log(resp);
          result = resp
          shortUrl = result.data.link
        });
      message = message + this.settings.msgForNewPatient + "<br>" + shortUrl
    }

    if (this.settings.location.address === "") {
      further_info = `
          <p>
            ${message}
          </p>
          <p><strong>Click here to </strong><a href="${environment.clientURL}/reschedule/${this.rescheduleDoc}">reschedule</a>
          <strong> or </strong><a href="${environment.clientURL}/cancel/${this.rescheduleDoc}">cancel</a><strong> this appointment.</strong></p>
          `;
    } else {
      if (this.formData.type != "Virtual Visit") {
        further_info = `
                <p>
                  <strong>You can reach us at: </strong><br />
                  ${this.settings.location.address}<br />`;

        if (this.settings.location.map_link !== "") {
          further_info += ` <a href="${this.settings.location.map_link}" target="_blank">Get Directions</a><br />`;
        }
      }
      further_info += `<strong>Contact No:</strong> <a href="tel:${this.settings.location.phone}">${this.settings.location.phone}</a>
                  <br /><br />
                  ${message}
                </p>`;
      if (this.settings.terms_conditions === true) {
        further_info += `<p><strong>Terms and Conditions agreed to: </strong> ${this.settings.terms_conditions_info}</p>`;
      }
      if (this.settings.payments === true) {
        further_info += `<p><strong>Payment Instructions: </strong> ${this.settings.payment_info}</p>`;
      }
      further_info += `<p><strong>Click here to </strong><a href="${environment.clientURL}/reschedule/${this.rescheduleDoc}">reschedule</a>
                <strong> or </strong><a href="${environment.clientURL}/cancel/${this.rescheduleDoc}">cancel</a> this appointment.</p>`;
    }


    let appointmentInfo = {
      appointment_with: appointment_with,
      date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.convertToLocalTime(
        this.selectedSlot.start
      )} - ${this.convertToLocalTime(
        this.selectedSlot.end
      )} ${this.getLocalTimezoneAbbr()}`,
      services: this.formData.services ? this.formData.services : "",
      to_email: this.formData.email,
      further_info: further_info,
      terms_conditions: this.settings.terms_conditions,
      terms_conditions_info: this.settings.terms_conditions_info,
      payments: this.settings.payments,
      payment_info: this.settings.payment_info,
      type: this.formData.type,
      servicesLabel: "Services opted for"
    };
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }

    let resp = await this.http.post(`${environment.appt_firebase.cloudFunctionDomain}/sendAppointmentEmailToUser`, appointmentInfo, { headers: new HttpHeaders({ "Content-Type": "text/plain" }) }).toPromise()
    console.log(resp);
    console.log("email Send to owner");
    // firebase
    //   .app("appt_firebase")
    //   .functions()
    //   .httpsCallable("sendAppointmentEmailToUser")(appointmentInfo)
    //   .then(
    //     (result) => {
    //       console.log("email sent to user");
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );
  }



  convertToLocalTime(timeString: string) {
    let tz_offset = moment().tz(this.timezone).format("Z");
    // return moment(`${timeString} ${tz_offset}`, "h:mm a Z").format("h:mm A");
    return moment(`${timeString}`, "h:mm a Z").format("h:mm A");
  }

  getLocalTimezoneAbbr() {
    // return moment.tz(moment.tz.guess()).format("z");
    return moment.tz(this.timezone).format("z");
  }

  saveJsonToFirebase() {
    firebase.initializeApp(
      {
        apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
        authDomain: "mktg-bot2.firebaseapp.com",
        databaseURL: "https://mktg-bot2.firebaseio.com",
        projectId: "mktg-bot2",
        storageBucket: "mktg-bot2.appspot.com",
        messagingSenderId: "1089334441394",
      },
      "mktg_prod_firebase"
    );

    let files = ["am-shivajinagar", "am-baner"];
    for (let file of files) {
      this.http
        .get("../../assets/json/" + file + ".json")
        .subscribe((contents) => {
          firebase
            .app("mktg_prod_firebase")
            .firestore()
            .collection("accounts")
            .doc("PfbrXzi8KybKF2gbj4M8")
            .collection("calendars")
            .add(contents)
            .then(
              (obj) => {
                console.log("Added " + file);
                console.log("The doc ID is: " + obj.id);
              },
              (error) => {
                console.error("Could not add " + file);
              }
            );
        });
    }
  }

  migrateCalendar() {
    firebase.initializeApp(
      {
        apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
        authDomain: "mktg-bot2.firebaseapp.com",
        databaseURL: "https://mktg-bot2.firebaseio.com",
        projectId: "mktg-bot2",
        storageBucket: "mktg-bot2.appspot.com",
        messagingSenderId: "1089334441394",
      },
      "prod_firebase"
    );

    firebase
      .app("prod_firebase")
      .firestore()
      .collection("accounts")
      .doc("4J61oN6uJk43LJITfoQR")
      .collection("calendars")
      .doc("")
      .get()
      .then((snapshot) => {
        let calendar = snapshot.data();
        calendar.slug = "";

        firebase
          .app("prod_firebase")
          .firestore()
          .collection("accounts")
          .doc("5MEDGlY1uCVzu7TzHbAH")
          .collection("calendars")
          .add(calendar)
          .then(
            (success) => {
              console.log(`Calendar added. ID: ${success.id}`);
            },
            (error) => {
              console.error(error);
            }
          );
      });
  }

  migrateCalendars() {
    firebase.initializeApp(
      {
        apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
        authDomain: "mktg-bot2.firebaseapp.com",
        databaseURL: "https://mktg-bot2.firebaseio.com",
        projectId: "mktg-bot2",
        storageBucket: "mktg-bot2.appspot.com",
        messagingSenderId: "1089334441394",
      },
      "prod_firebase"
    );

    let from_collection = "accounts/PfbrXzi8KybKF2gbj4M8/calendars";
    let to_collection = "accounts/2L3Br0HVUicnPY2qwyAF/calendars";

    firebase
      .app("prod_firebase")
      .firestore()
      .collection(from_collection)
      .get()
      .then((docsSnapshot) => {
        docsSnapshot.forEach((doc) => {
          let calendar = doc.data();
          firebase
            .app("prod_firebase")
            .firestore()
            .collection(to_collection)
            .add(calendar)
            .then(
              (success) => {
                console.log(
                  "Migrated calendar successfully! Id: " + success.id
                );
              },
              (error) => {
                console.error(error);
              }
            );
        });
      });
  }

  migrateContact(contact: any) {
    return (
      firebase
        .firestore()
        .collection("accounts")
        .doc("5MEDGlY1uCVzu7TzHbAH")
        .collection("contacts")
        //return firebase.firestore().collection('accounts').doc('tIT9fFK3flNU5bvdNt4w').collection('contacts')
        .where("email", "==", contact.email)
        .get()
        .then((snapshot) => {
          //console.log(snapshot);
          if (snapshot.empty === true) {
            return (
              firebase
                .firestore()
                .collection("accounts")
                .doc("5MEDGlY1uCVzu7TzHbAH")
                .collection("contacts")
                .add(contact)
                //return firebase.firestore().collection('accounts').doc('tIT9fFK3flNU5bvdNt4w').collection('contacts').add(contact)
                .then((contactRef) => {
                  this.contactRef = contactRef;
                  return this.contactRef;
                })
            );
          } else {
            return snapshot.docs[0].ref;
          }
        })
    );
  }

  migrateAppointments() {
    firebase
      .app("appt_firebase")
      .firestore()
      .collection("appointments")
      .get()
      .then((docsSnapshot) => {
        docsSnapshot.forEach((doc) => {
          let appt = doc.data();
          let contact = {
            name: appt.name,
            phone: appt.phone_no,
            email: appt.email,
          };
          this.migrateContact(contact).then((contactRef) => {
            let slot_split = appt.slot.split(" - ");
            appt.contact = contactRef;
            (appt.appt_start = moment
              .tz(
                `${appt.date} ${slot_split[0]}`,
                "MMMM D, YYYY h:mm a",
                "America/New_York"
              )
              .toDate()),
              (appt.appt_end = moment
                .tz(
                  `${appt.date} ${slot_split[1]}`,
                  "MMMM D, YYYY h:mm a",
                  "America/New_York"
                )
                .toDate()),
              (appt.status = "booked");
            //console.log(appt);
            firebase
              .firestore()
              .collection("accounts")
              .doc("5MEDGlY1uCVzu7TzHbAH")
              .collection("appointments")
              .add(appt)
              //firebase.firestore().collection('accounts').doc('tIT9fFK3flNU5bvdNt4w').collection('appointments').add(appt)
              .then(
                (success) => {
                  console.log("Migrated appt successfully!");
                },
                (error) => {
                  console.error(error);
                }
              );
          });
        });
      });
  }

  createRescheduleDocs() {
    firebase.initializeApp(
      {
        apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
        authDomain: "mktg-bot2.firebaseapp.com",
        databaseURL: "https://mktg-bot2.firebaseio.com",
        projectId: "mktg-bot2",
        storageBucket: "mktg-bot2.appspot.com",
        messagingSenderId: "1089334441394",
      },
      "mktg_prod_firebase"
    );

    firebase
      .app("mktg_prod_firebase")
      .firestore()
      .collection("accounts")
      .doc("tIT9fFK3flNU5bvdNt4w")
      .collection("appointments")
      .get()
      .then((docsSnapshot) => {
        docsSnapshot.forEach((doc) => {
          let appt_ref = doc.ref;

          firebase
            .app("mktg_prod_firebase")
            .firestore()
            .collection("reschedules")
            .add({
              appt_ref: appt_ref,
              redirectUrl:
                "/reschedule-calendar/dental-designer/dental-calendar",
            })
            .then((docRef) => {
              appt_ref
                .update({
                  reschedule_doc: docRef.id,
                })
                .then(
                  () => {
                    console.log(
                      "Created reschedule doc for ID: " + appt_ref.id
                    );
                  },
                  (error) => {
                    console.error(
                      "Could not create a reschedule doc for ID: " + appt_ref.id
                    );
                  }
                );
            });
        });
      });
  }

  updateTimeslots() {
    let timeslots = [
      {
        day: "Sunday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
        ],
      },
      {
        day: "Monday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
          {
            start: "12:30 PM",
            end: "01:00 PM",
          },
          {
            start: "01:00 PM",
            end: "01:30 PM",
          },
          {
            start: "01:30 PM",
            end: "02:00 PM",
          },
          {
            start: "02:00 PM",
            end: "02:30 PM",
          },

          {
            start: "02:30 PM",
            end: "03:00 PM",
          },

          {
            start: "03:00 PM",
            end: "03:30 PM",
          },
          {
            start: "03:30 PM",
            end: "04:00 PM",
          },
          {
            start: "04:00 PM",
            end: "04:30 PM",
          },
          {
            start: "04:30 PM",
            end: "05:00 PM",
          },
          {
            start: "05:00 PM",
            end: "05:30 PM",
          },
          {
            start: "05:30 PM",
            end: "06:00 PM",
          },
          {
            start: "06:00 PM",
            end: "06:30 PM",
          },

          {
            start: "06:30 PM",
            end: "07:00 PM",
          },
          {
            start: "07:00 PM",
            end: "07:30 PM",
          },
          {
            start: "07:30 PM",
            end: "08:00 PM",
          },


        ],
      },
      {
        day: "Tuesday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
        ],
      },
      {
        day: "Wednesday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
        ],
      },
      {
        day: "Thursday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
        ],
      },
      {
        day: "Friday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
        ],
      },
      {
        day: "Saturday",
        slots: [
          {
            start: "7:00 AM",
            end: "7:30 AM",
          },
          {
            start: "7:30 AM",
            end: "8:00 AM",
          },
          {
            start: "8:00 AM",
            end: "8:30 AM",
          },
          {
            start: "8:30 AM",
            end: "9:00 AM",
          },
          {
            start: "9:00 AM",
            end: "9:30 AM",
          },
          {
            start: "9:30 AM",
            end: "10:00 AM",
          },
          {
            start: "10:00 AM",
            end: "10:30 AM",
          },
          {
            start: "10:30 AM",
            end: "11:00 AM",
          },
          {
            start: "11:00 AM",
            end: "11:30 AM",
          },
          {
            start: "11:30 AM",
            end: "12:00 PM",
          },
        ],
      },
    ];

    firebase.initializeApp(
      {
        apiKey: "AIzaSyCYSZZUmLcaP3Pz6C70A4Uu9kAniE9tWwM",
        authDomain: "mktg-bot2.firebaseapp.com",
        databaseURL: "https://mktg-bot2.firebaseio.com",
        projectId: "mktg-bot2",
        storageBucket: "mktg-bot2.appspot.com",
        messagingSenderId: "1089334441394",
      },
      "mktg_prod_firebase"
    );

    firebase
      .app("mktg_prod_firebase")
      .firestore()
      .collection("accounts")
      .doc("T7f7d2VEGjqXmQvkHZ6h")
      .collection("calendars")
      .doc("8zRevyosXZez4mJko6bf")
      .get()
      .then((docSnapshot) => {
        docSnapshot.ref
          .update({
            timeslots: timeslots,
            timezone: "America/New_York",
          })
          .then(
            () => {
              console.log("The document has got updated.");
            },
            (error) => {
              console.error(error);
            }
          );
      });
  }

  setContactDetails(accId: string, contactId: string) {
    firebase
      .firestore()
      .collection("accounts")
      .doc(accId)
      .collection("contacts")
      .doc(contactId)
      .get()
      .then((contactSnapshot) => {
        this.contactRef = contactSnapshot.ref;
        let contact = contactSnapshot.data();
        this.formData = {
          first_name: contact.first_name,
          last_name: contact.last_name,
          countryCode: contact.countryCode,
          phone_no: contact.phone,
          email: contact.email,
          send_text_reminders: contact.send_text_reminders,
          patient: "No",
          services: "",
          message: "",
        };

        if (contact.birthDate) {
          this.formData.birthDate = contact.birthDate.toDate()
        }

        if ("address" in contact) {
          if (typeof contact["address"] === "object") {
            this.formData.street = contact.address.street;
            this.formData.city = contact.address.city;
            this.formData.state = contact.address.state;
            this.formData.country = contact.address.country;
            this.formData.zip = contact.address.zip;
          } else {
            this.formData.street = "";
            this.formData.city = "";
            this.formData.state = "";
            this.formData.country = "";
            this.formData.zip = "";
          }
        } else {
          this.formData.street = "";
          this.formData.city = "";
          this.formData.state = "";
          this.formData.country = "";
          this.formData.zip = "";
        }
      });
  }

  getApointmentThemeSetting() {
    return this.acc_ref
      .collection("settings").where("type", "==", "appointment-theme").get()
  }

  getContactDetails(contactId: any) {
    return this.acc_ref
      .collection("contacts").doc(contactId).get()
  }
  getProviderDetails() {
    return this.acc_ref
      .collection("providersDetails").where("slug", "==", this.calendar_slug).get()
  }


  setrouterActivity() {
    this.routerPaths.splice(this.routerPaths.length - 1, 1);
  }

  routeback() {
    for (let i = this.routerPaths.length - 2; i >= 0; i--) {
      this.router.navigate([this.routerPaths[i].url]);
      break;
    }
  }

  bookAnotherAppointment() {
    window.location.href = this.settings.payments == true ? `/calendar/${this.acc_slug}/${this.calendar_slug}` : this.routerPaths[0].url
  }

  getClincImage() {
    return this.settings.clinic_img_url ? this.settings.clinic_img_url : this.accDetails.clinic_img_url
  }



  // recurreingAppointment function

  confirmAppointmentAvailable(date, slot) {
    return this.acc_ref
      .collection("appointments")
      .where("date", "==", date.format("MMMM D, YYYY"))
      .where(
        "slot",
        "==",
        `${slot.start} - ${slot.end}`
      )
      .where("calendarReference", "==", this.calendarRef)
      .where("status", "==", "booked")
      .get()
      .then((querySnapshot) => {
        return querySnapshot.empty;
      });
  }





  getRecurringSchedule() {
    return {
      recurringSchedule: this.recuuringSchedule,
      index: this.recurringIndex
    }
  }

  async createrecuringAppointmentsReferenceDtails(contactRef, recuringAppointmentsRefDtails, oldAppointmentRerence?) {
    let recurringDetail: any = {}
    if (oldAppointmentRerence) {
      recurringDetail = {
        contactRef: contactRef,
        appointmentDetails: recuringAppointmentsRefDtails,
        oldAppointmentRerence: oldAppointmentRerence,
        created_on: moment().toDate(),
      }
      oldAppointmentRerence.update({ status: "rescheduled" })
    }

    else {
      recurringDetail = {
        contactRef: contactRef,
        appointmentDetails: recuringAppointmentsRefDtails,
        created_on: moment().toDate(),
      }

    }

    await this.acc_ref.collection("reccuring_appointment_details").add(recurringDetail).then(
      async (apptDetailRef) => {
        this.apptDetailRefId = apptDetailRef.id
        console.log(apptDetailRef);
        await this.acc_ref.collection("reccuring_appointment_details").doc(apptDetailRef.id).get().then(resp => {
          let result = resp.data();
          console.log(result)
          result.appointmentDetails.forEach(appRef => {
            appRef.update({ recurringApptDetailReference: this.acc_ref.collection("reccuring_appointment_details").doc(apptDetailRef.id) })
          });
        }
        )
      })
  }

  async saveRecurringAppointments(contactId, recuringAppointments) {
    var numberApend = ['1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th']

    let transactionIDArray = []
    for (let j = 0; j < recuringAppointments.length; j++) {
      transactionIDArray.push(this.acc_ref.collection("appointments").doc())
    }
    console.log(transactionIDArray)

    let slotNotAvailable = 0
    let contactRef = this.acc_ref.collection("contacts").doc(contactId);
    try {
      const res = await firebase.firestore().runTransaction(async transaction => {

        for (let i = 0; i < recuringAppointments.length; i++) {
          let element = recuringAppointments[i]

          await this.confirmAppointmentAvailable(element.date, element.selectedSlot).then(async (available) => {
            if (available) {
              await transaction.set(transactionIDArray[i], {
                bookedOn: moment().toDate(),
                date: element.date.format("MMMM D, YYYY"),
                slot: `${element.selectedSlot.start} - ${element.selectedSlot.end}`,
                appt_start: moment
                  .tz(
                    `${element.date.format("YYYY-MM-DD")} ${element.selectedSlot.start
                    }`,
                    "YYYY-MM-DD h:mm a",
                    this.timezone
                  )
                  .toDate(),
                appt_end: moment
                  .tz(
                    `${element.date.format("YYYY-MM-DD")} ${element.selectedSlot.end
                    }`,
                    "YYYY-MM-DD h:mm a",
                    this.timezone
                  )
                  .toDate(),
                contactRefrence: contactRef,
                calendarReference: this.calendarRef,
                status: 'booked',
              });

              element.saved = true
            }


            else {
              let dialogRef = this.dialog.open(ConfirmationDialogComponent, {
                data: {
                  header: "Your " + numberApend[i] + "  Follow Up visit slot is alredy booked.You should have to Book all appointments from " + numberApend[i] + " Follow Up visit",
                  type: 'reboook'
                }
              });
              slotNotAvailable++;

              dialogRef.afterClosed().subscribe(result => {
                recuringAppointments[i].slotConfirm = false;
                recuringAppointments[i].selectedSlot = {};
                // this.showClaendar = index;
                for (let j = i + 1; j < recuringAppointments.length; j++) {
                  recuringAppointments[j].slotConfirm = false;
                  recuringAppointments[j].selectedSlot = {};
                  recuringAppointments[j].date = {};
                  recuringAppointments[j].dateSelected = false
                  recuringAppointments[j].selectedDate = {};
                }
                this.recurringIndex = i
                this.recuuringSchedule = recuringAppointments
                this.router.navigate(['recurring/rebook/pandit-clinic/dr.sharvari/QSYAy0m1gLgLBFNv3UCX']);
              });
            }

          });
          if (slotNotAvailable > 0)
            break
        }
        if (slotNotAvailable > 0)
          return Promise.reject('Appointment Slot not available"');
        else
          return Promise.resolve("transction Succesful")
      }).then(resp => {
        this.createrecuringAppointmentsReferenceDtails(contactRef, transactionIDArray)
        console.log(resp)
      })

    } catch (e) {
      console.log('Transaction failure:', e);
    }
  }

  getReccuringAppitmentsInfoByReferenceId(appointmentReferenceId: string) {
    return this.acc_ref.collection("reccuring_appointment_details").doc(appointmentReferenceId).get()
  }

  getRecuuringAppointentGroupForReschedule(recuuringDetailsRference) {
    return this.acc_ref.collection("appointments")
      .where('recurringApptDetailReference', '==', recuuringDetailsRference)
      .get()
  }

  async setAccReferenceByAccSlug(acc_slug: string): Promise<boolean> {
    let acc = await firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", acc_slug)
      .get();
    this.acc_ref = acc.docs[0].ref
    return true
  }

  async getRecuuringCalenders() {
    return this.acc_ref.collection("calendars").orderBy('recuringCalndarSetting').get()
  }

  sendFollowUpAppointmentEmailToOwner(contactInfo, followUpSchedule) {
    let follow_up_schedule = []
    followUpSchedule.forEach(element => {
      follow_up_schedule.push({
        date: element.date.format("MMMM D, YYYY"),
        slot: `${element.selectedSlot.start} - ${element.selectedSlot.end}`
      })
    });
    console.log(contactInfo, followUpSchedule)
    let folloUpVisitInfo = {
      form_data: {
        first_name: contactInfo.first_name,
        last_name: contactInfo.last_name,
        countryCode: contactInfo.countryCode,
        phone_no: contactInfo.phone,
        email: contactInfo.email
      },
      to_email: this.settings.owner_email,
      source: 'website',
      booked_on: moment().toDate().toDateString(),
      providerName: this.providersName,
      follow_up_schedule: follow_up_schedule,
      links_msg: `<strong>Click here to </strong><a href="${environment.clientURL}/recurring/${this.acc_slug}/reschedule/${this.calendar_slug}/${this.apptDetailRefId}">reschedule</a>
      <strong> or </strong><a href="${environment.clientURL}/recurring/${this.acc_slug}/cancel/${this.apptDetailRefId}">cancel</a><strong> this appointment.</strong>`,
    }
    console.log(folloUpVisitInfo)
    return firebase
      .functions()
      .httpsCallable("sendFollowUpAppointmentEmailToOwner")(folloUpVisitInfo)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }

  sendFollowUpAppointmentEmailToUser(contactInfo, followUpSchedule) {
    let message = this.settings.message == undefined ? "" : this.settings.message;
    let further_info = `
    <p>
      <strong>You can reach us at: </strong><br />
      ${this.settings.location.address}<br />`;

    if (this.settings.location.map_link !== "") {
      further_info += ` <a href="${this.settings.location.map_link}" target="_blank">Get Directions</a><br />`;
    }
    further_info += `<strong>Contact No:</strong> <a href="tel:${this.settings.location.phone}">${this.settings.location.phone}</a>
      <br /><br />
      ${message}
    </p>`;

    let follow_up_schedule = []
    followUpSchedule.forEach(element => {
      follow_up_schedule.push({
        date: element.date.format("MMMM D, YYYY"),
        slot: `${element.selectedSlot.start} - ${element.selectedSlot.end}`
      })
    });
    let folloUpVisitInfo = {
      follow_up_schedule: follow_up_schedule,
      appointment_with: this.acc_name,
      to_email: contactInfo.email,
      providerName: this.providersName,
      further_info: further_info
    }
    console.log(folloUpVisitInfo)
    return firebase
      .functions()
      .httpsCallable("sendFollowUpAppointmentEmailToUser")(folloUpVisitInfo)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }

  sendRescheduleFollowUpAppointmentEmailToOwner(contactInfo, newfollowUpSchedule, oldfollowUpSchedule) {
    let new_follow_up_schedule = []
    newfollowUpSchedule.forEach(element => {
      new_follow_up_schedule.push({
        date: element.date.format("MMMM D, YYYY"),
        slot: `${element.selectedSlot.start} - ${element.selectedSlot.end}`
      })
    });

    let old_follow_up_schedule = []
    oldfollowUpSchedule.forEach(element => {
      old_follow_up_schedule.push({
        date: element.date,
        slot: element.slot
      })
    });
    let folloUpVisitInfo = {
      form_data: {
        first_name: contactInfo.first_name,
        last_name: contactInfo.last_name,
        countryCode: contactInfo.countryCode,
        phone_no: contactInfo.phone,
        email: contactInfo.email
      },
      to_email: this.settings.owner_email,
      rescheduled_on: moment().toDate().toDateString(),
      providerName: this.providersName,
      follow_up_schedule: new_follow_up_schedule,
      location: this.acc_name,
      old_follow_up_schedule: old_follow_up_schedule,
      links_msg: `<strong>Click here to </strong><a href="${environment.clientURL}/recurring/${this.acc_slug}/reschedule/${this.calendar_slug}/${this.apptDetailRefId}">reschedule</a>
      <strong> or </strong><a href="${environment.clientURL}/recurring/${this.acc_slug}/cancel/${this.apptDetailRefId}">cancel</a><strong> this appointment.</strong>`,
    }

    return firebase
      .functions()
      .httpsCallable("sendRescheduleFollowUpAppointmentEmailToOwner")(folloUpVisitInfo)
      .then(resp => {
        console.log(resp);
        return resp;
      });

  }

  sendRescheduleFollowUpAppointmentEmailTouser(contactInfo, newfollowUpSchedule, oldfollowUpSchedule) {
    let new_follow_up_schedule = []
    newfollowUpSchedule.forEach(element => {
      new_follow_up_schedule.push({
        date: element.date.format("MMMM D, YYYY"),
        slot: `${element.selectedSlot.start} - ${element.selectedSlot.end}`
      })
    });

    let old_follow_up_schedule = []
    oldfollowUpSchedule.forEach(element => {
      old_follow_up_schedule.push({
        date: element.date,
        slot: element.slot
      })
    });

    let message = this.settings.message == undefined ? "" : this.settings.message;
    let further_info = `
    <p>
      <strong>You can reach us at: </strong><br />
      ${this.settings.location.address}<br />`;

    if (this.settings.location.map_link !== "") {
      further_info += ` <a href="${this.settings.location.map_link}" target="_blank">Get Directions</a><br />`;
    }
    further_info += `<strong>Contact No:</strong> <a href="tel:${this.settings.location.phone}">${this.settings.location.phone}</a>
      <br /><br />
      ${message}
    </p>`;
    let folloUpVisitInfo = {
      appointment_with: this.acc_name,
      to_email: contactInfo.email,
      rescheduled_on: moment().toDate().toDateString(),
      providerName: this.providersName,
      follow_up_schedule: new_follow_up_schedule,
      old_follow_up_schedule: old_follow_up_schedule,
      further_info: further_info
    }

    return firebase
      .functions()
      .httpsCallable("sendRescheduleFollowUpAppointmentEmailToUser")(folloUpVisitInfo)
      .then(resp => {
        console.log(resp);
        return resp;
      });

  }

  cancelFollowUpAppointmentEmailToOwner(contactInfo, followUpSchedule) {

    let follow_up_schedule = []
    followUpSchedule.forEach(element => {
      follow_up_schedule.push({
        date: element.date,
        slot: element.slot
      })
    });


    let folloUpVisitInfo = {
      form_data: {
        first_name: contactInfo.first_name,
        last_name: contactInfo.last_name,
        countryCode: contactInfo.countryCode,
        phone: contactInfo.phone,
        email: contactInfo.email
      },
      to_email: this.settings.owner_email,
      providerName: this.providersName,
      follow_up_schedule: follow_up_schedule,
    }
    return firebase
      .functions()
      .httpsCallable("cancelFollowUpAppointmentEmailToOwner")(folloUpVisitInfo)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }

  cancelFollowUpAppointmentEmailToUser(contactInfo, followUpSchedule) {
    let follow_up_schedule = []
    followUpSchedule.forEach(element => {
      follow_up_schedule.push({
        date: element.date,
        slot: element.slot
      })
    });

    let folloUpVisitInfo = {
      appointment_with: this.acc_name,
      to_email: contactInfo.email,
      providerName: this.providersName,
      follow_up_schedule: follow_up_schedule,
    }

    return firebase
      .functions()
      .httpsCallable("cancelFollowUpAppointmentEmailToUser")(folloUpVisitInfo)
      .then(resp => {
        console.log(resp);
        return resp;
      });
  }

  setSlotFoundIndex(index) {
    this.slotIndexFound.next(
      {
        index: index
      }
    )
  }

  async createBitlyUrl() {
    let result: any = {}
    let headers = new HttpHeaders({
      'Authorization': "Bearer b4b629fbc9296bad88f325583c045624108a1046",
      'Content-Type': 'application/json'
    })

    result = await this.http.post("https://api-ssl.bitly.com/v4/shorten", { "long_url": `${environment.patientFormUrl}/patient-form/${this.acc_slug}`, "domain": "bit.ly" }, { headers: headers }).toPromise()
    return result.link
  }
  async appointmentStutus(formResponseId) {
    this.formRespId = formResponseId
  }

  async sendRequestedAppointmentEmailToUser() {
    let further_info = "";
    let appointment_with = this.acc_name;
    let message = ''
    if (this.formData.type == "Virtual Visit") {
      message = this.settings.virtualEmailMessege
    }
    else {
      message = this.settings.message === undefined ? "" : this.settings.message;
    }


    if (this.settings.location.address === "") {
      further_info = `
          <p>
            ${message}
          </p>`
        ;
    } else {
      if (this.formData.type != "Virtual Visit") {
        further_info = `
                <p>
                  <strong>You can reach us at: </strong><br />
                  ${this.settings.location.address}<br />`;

        if (this.settings.location.map_link !== "") {
          further_info += ` <a href="${this.settings.location.map_link}" target="_blank">Get Directions</a><br />`;
        }
      }
      further_info += `<strong>Contact No:</strong> <a href="tel:${this.settings.location.phone}">${this.settings.location.phone}</a>
                  <br /><br />
                  ${message}
                </p>`;
      if (this.settings.terms_conditions === true) {
        further_info += `<p><strong>Terms and Conditions agreed to: </strong> ${this.settings.terms_conditions_info}</p>`;
      }
    }


    let appointmentInfo = {
      appointment_with: appointment_with,
      date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.convertToLocalTime(
        this.selectedSlot.start
      )} - ${this.convertToLocalTime(
        this.selectedSlot.end
      )} ${this.getLocalTimezoneAbbr()}`,
      services: this.formData.services,
      to_email: this.formData.email,
      further_info: further_info,
      terms_conditions: this.settings.terms_conditions,
      terms_conditions_info: this.settings.terms_conditions_info,
      payments: this.settings.payments,
      payment_info: this.settings.payment_info,
      type: this.formData.type,
      servicesLabel: "Services opted for"
    };
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }



    let resp = await this.http.post(`${environment.appt_firebase.cloudFunctionDomain}/sendRequestedAppointmentEmailToUser`, appointmentInfo, { headers: new HttpHeaders({ "Content-Type": "text/plain" }) }).toPromise()
    console.log(resp);
    console.log("email Send to user");
    // firebase
    //   .app("appt_firebase")
    //   .functions()
    //   .httpsCallable("sendRequestedAppointmentEmailToUser")(appointmentInfo)
    //   .then(
    //     (result) => {
    //       console.log("email sent to user");
    //     },
    //     (error) => {
    //       console.error(error);
    //     }
    //   );
  }


  async sendRequestedAppointmentEmailToOwner() {
    // console.log(form_data);
    console.log("In send Email to owner function ");

    let to_email = this.settings.owner_email;

    // this.formData['patientType']= this.formData['patientType'] === 'Yes' ? 'New' : 'Old';
    let appointmentInfo = {
      form_data: this.formData,
      date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.selectedSlot.start} - ${this.selectedSlot.end} ${moment
        .tz(this.timezone)
        .format("z")}`,
      to_email: to_email,
      source: this.source,
      booked_on: this.bookedOn.tz(this.timezone).format("MMMM D, Y h:mm A z"),

      location: this.settings.location.name,
      terms_conditions: this.settings.terms_conditions,
      terms_conditions_info: this.settings.terms_conditions_info,
      further_info: `Click on the following button to accept or reject the appointment.\n\n<div style="margin-top:10px;">
      <a href="${environment.clientURL}/appointment-request/${this.rescheduleDoc}" style='border-radius:4px;display:inline-block;font-size:14px;font-weight:bold;line-height:24px;padding:5px 20px;text-align:center;text-decoration:none!important;color:#ffffff!important;background-color:#7e84f2;font-family:Arial,sans-serif'>
      Accept or Reject
      </a>
  </div>`
    };
    if ("form_fields" in this.settings) {
      if (this.settings.form_fields.message.show === true) {
        appointmentInfo["title"] =
          this.settings.form_fields.message.title !== ""
            ? this.settings.form_fields.message.title
            : "Message";
      }
    }
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }
    console.log(appointmentInfo);
    let resp = await this.http.post(`${environment.appt_firebase.cloudFunctionDomain}/sendRequestedAppointmentEmailToOwner`, appointmentInfo, { headers: new HttpHeaders({ "Content-Type": "text/plain" }) }).toPromise()
    console.log(resp);
    console.log("email Send to owner");
    // firebase
    //   .app("appt_firebase")
    //   .functions()
    //   .httpsCallable("sendRequestedAppointmentEmailToOwner")(appointmentInfo)
    //   .then(
    //     (result) => {
    //       console.log("email sent to owner");
    //     },
    //     (error) => {
    //       console.log("error in send email to owner");
    //       console.error(error);
    //     }
    //   );
  }

}
