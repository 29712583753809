import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import firebase from "firebase/app";
// import { AngularFireDatabase } from "angularfire2/database";
// import {
//   AngularFirestore,
//   AngularFirestoreDocument,
// } from "angularfire2/firestore";
// import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import * as moment from "moment-timezone";
import { environment } from "../../environments/environment";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { DeviceDetectorService } from "ngx-device-detector";

@Injectable({
  providedIn: "root",
})
export class RescheduleAppointmentService {

  getCategory() {
    throw new Error("Method not implemented.");
  }
  setCategory(category: any) {
    throw new Error("Method not implemented.");
  }

  public acc_slug: string;
  public acc_name: string;
  public calendar_slug: string;
  public group_slug: string;
  public multipleLocations: boolean;
  public multipleProviders: boolean;
  public providerLocations: boolean;
  private selectedDate: any;
  private selectedSlot: any;
  private timezone: string;
  private source: string;
  private settings: any;
  private bookedOn: any;
  public rescheduleDoc: string;
  public appt_ref: any;
  public contact_ref: any;
  public formData: any;
  public provider_slug: string;
  public group_type: string;
  public providersGroupData;
  public calendarRef: any;
  public providerId: any;
  public providersName;
  public providerRef;
  public oldProviderName;
  public oldProviderRef;
  appointment: any;
  bookingDate: any;
  public address: any;
  private contactRef: any;
  private acc_ref: any;
  private createdOn: any;
  private services: string = "";
  accDetails: any;
  url: string;
  appointment_details: any = {};
  redirectUrl: string;
  oldProviderEmail: string;
  currentPoviderEmail: string;
  public routerPaths: any[] = []
  public apptConfirmed: boolean = false
  // http://localhost:4200/reschedule/54lbDfoHoea5w93PKlc4

  constructor(
    private http: HttpClient,
    // public afs: AngularFirestore,
    private router: Router,
    private deviceService: DeviceDetectorService

  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        for (let i = 0; i < this.routerPaths.length; i++) {
          if (event.url === this.routerPaths[i].url) {
            this.routerPaths.splice(i, this.routerPaths.length - i + 1);
          }
        }
        if (event.url.includes("?")) {
          event.url = event.url.split("?")[0];
        }
        let navigationEventObj: any = {}
        navigationEventObj = event
        navigationEventObj.isAutoRoute = false
        this.routerPaths.push(event);
        console.log(this.routerPaths)
      }

    });
  }

  async getProvidersCalendar(acc_slug: string, provider_slug: string) {
    this.acc_slug = acc_slug;
    this.provider_slug = provider_slug;
    this.group_type = "reschedule-provider-groups";
    let acc = await firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", this.acc_slug)
      .get();
    this.acc_ref = acc.docs[0].ref;
    this.accDetails = acc.docs[0].data();
    this.acc_name = this.accDetails.name;
    let providerCalendarDoc = await this.acc_ref
      .collection("providers-groups")
      .where("slug", "==", this.provider_slug)
      .get();
    let providerData = {};
    this.providersGroupData = [];
    providerCalendarDoc.docs[0]
      .data()
      .providersReference.forEach((providerRef) => {
        this.getDataOfReference(providerRef).then((docData) => {
          providerData = docData;
          let calendarArr = [];
          docData.calendar_link.forEach((calendarRef) => {
            this.getCalendar(calendarRef).then((calendarData) => {
              calendarData["ref"] = calendarRef;
              calendarArr.push(calendarData);
            });
            providerData["calendarData"] = calendarArr;
          });
          this.providersGroupData.push(providerData);
        });
      });
    return this.providersGroupData;
  }

  async getDataOfReference(docRef: any) {
    let doc = await docRef.get();
    let docData = doc.data();
    docData["docId"] = doc.id;
    return docData;
  }

  async getCalendarGroups(acc_slug: string, group_slug: string) {
    this.acc_slug = acc_slug;
    this.group_slug = group_slug;

    let unresolvedCalendars = [];

    let acc = await firebase
      .firestore()
      .collection("accounts")
      .where("account_slug", "==", this.acc_slug)
      .get();

    this.acc_ref = acc.docs[0].ref;
    this.accDetails = acc.docs[0].data();
    this.acc_name = this.accDetails.name;
    // this.acc_name = acc.docs[0].data().name;

    let calendarGroupDoc = await this.acc_ref
      .collection("calendar-groups")
      .where("slug", "==", this.group_slug)
      .get();

    calendarGroupDoc.docs[0].data().calendars.forEach((calendarRef) => {
      unresolvedCalendars.push(this.getCalendar(calendarRef));
    });

    let calendars = await Promise.all(unresolvedCalendars);
    return calendars;
  }

  getProviderCalendarData(
    documentID //return providers details along with calendars
  ) {
    console.log(documentID, this.providersGroupData);
    let providerObj = this.providersGroupData.filter(
      (doc) => doc.docId === documentID
    );
    console.log(providerObj);
    return providerObj;
  }

  async getCalendar(calendarRef: any) {
    let calendar = await calendarRef.get();
    return calendar.data();
  }

  fetchSettings() {
    return firebase
      .firestore()
      .collection("appointments-settings")
      .doc(`${environment.calendar_settings_id}`)
      .get();
  }

  setSettings(settings: any) {
    this.settings = settings;
  }

  getSettings() {
    return this.settings;
  }

  getSelectedDate() {
    return this.selectedDate;
  }

  getSelectedSlot() {
    return this.selectedSlot;
  }

  getTimezone() {
    return this.timezone;
  }

  getSource() {
    return this.source;
  }

  setSelectedDate(date: any) {
    this.selectedDate = date;
  }

  setSelectedSlot(slot: any) {
    this.selectedSlot = slot;
  }

  setTimezone(timezone: string) {
    this.timezone = timezone;
  }

  setSource(source: string) {
    this.source = source;
  }

  getSelectedServices() {
    return this.services;
  }

  setSelectedServices(services: string) {
    this.services = services;
  }

  setFormData(formData: any) {
    this.formData = formData;
    console.log(this.formData);
  }

  setProviderDetails(providerId) {
    let providerObj = this.providersGroupData.filter(
      (doc) => doc.docId === providerId
    );
    this.providersName = providerObj[0]["name"];
    this.providerRef = providerObj[0]["providerRef"];
    this.currentPoviderEmail = providerObj[0]["email"];
    // return providerObj;
  }

  getProvidersDetails(providerRef) {
    return providerRef.get().then((providerSnap) => {
      let providerData = providerSnap.data();
      this.providersName = providerData.name;
      this.providerRef = providerSnap.ref;
      return this.providersName;
    });
    // return this.acc_ref
    //   .collection("providersDetails")
    //   .where("calendar_link", "array-contains", this.calendarRef)
    //   .limit(1)
    //   .get()
    //   .then(docs => {
    //     docs.forEach(docSnap => {
    //       let providerData = docSnap.data();
    //       this.providersName = providerData["name"];
    //       this.providerRef = docSnap.ref;
    //     });
    //     return this.providersName;
    //   });
  }

  checkSlotsAvailability() {
    //return firebase.firestore().collection('appointments')
    return this.acc_ref
      .collection("appointments")
      .where("date", "==", this.selectedDate.format("MMMM D, YYYY"))
      .get()
      .then((appointmentsRef) => {
        let bookedSlots = [];
        appointmentsRef.forEach((appointment) => {
          let slot = {
            slot: appointment.data().slot,
            status: appointment.data().status,
          };
          bookedSlots.push(slot);
        });
        return bookedSlots;
      });
  }

  confirmAppointmentValidity() {
    //return firebase.firestore().collection('appointments')
    let query = this.acc_ref.collection("appointments").where("date", "==", this.selectedDate.format("MMMM D, YYYY"))
      .where("slot", "==", `${this.selectedSlot.start} - ${this.selectedSlot.end}`)
      .where("status", "==", "booked")

    if (this.calendarRef) {
      query = query.where("calendarReference", "==", this.calendarRef)
    }


    return query.get()
      .then((querySnapshot) => {
        return querySnapshot.empty;
      });
  }

  async getCalendarSettings(acc_slug: string, calendar_slug: string) {
    this.acc_slug = acc_slug;
    this.calendar_slug = calendar_slug;

    let accountSnapshot = await firebase.firestore().collection("accounts").where("account_slug", "==", acc_slug).get()
    let acc_id = accountSnapshot.docs[0].id;
    this.acc_ref = accountSnapshot.docs[0].ref;
    this.accDetails = accountSnapshot.docs[0].data();
    this.acc_name = this.accDetails.name;
    if (this.appointment_details.subAccountRef) {
      let subAccountSnapshot = await this.appointment_details.subAccountRef.get()
      let subAccountData = subAccountSnapshot.data()
      this.accDetails.name = subAccountData.name
      this.acc_name = subAccountData.name
    }

    return firebase
      .firestore()
      .collection("accounts")
      .doc(acc_id)
      .collection("calendars")
      .where("slug", "==", calendar_slug)
      .get();
  }

  fetchAppointmentDetails(acc_slug: string, appt_id: string) {
    this.acc_slug = acc_slug;
    console.log(this.acc_slug);
    let app = firebase
      .firestore()
      .collection("accounts")
      .doc(this.acc_slug)
      .collection("appointments")
      .doc(appt_id);
    console.log(app);
  }

  checkSubAccountExist(subAccountsArray, newSubAccountRef) {
    let subAccountExist = false;
    subAccountsArray.forEach(subAccount => {
      if (subAccount.id == newSubAccountRef.id) {
        subAccountExist = true;
      }
    });
    return subAccountExist;
  }

  async saveContact() {
    this.createdOn = moment();
    let contact = {
      //"name": `${formData.first_name} ${formData.last_name}`,
      first_name: this.formData.first_name,
      last_name: this.formData.last_name,
      phone: this.formData.phone_no,
      email: this.formData.email,
      countryCode: this.formData.countryCode,
      full_phone: this.formData.countryCode + this.formData.phone_no,
    };


    //return firebase.firestore().collection('accounts').doc('7XZFnNuPDWuDqwOzc8cu').collection('contacts')

    let query = this.acc_ref.collection("contacts").where("full_phone", "==", contact.full_phone)
    let conatctQuerySnapshot = await query.get()

    if (conatctQuerySnapshot.docs.length == 0) {
      query = this.acc_ref.collection("contacts").where("email", "==", contact.email)
      conatctQuerySnapshot = await query.get()
    }

    if (conatctQuerySnapshot.docs.length == 0) {
      contact["created_on"] = this.createdOn.toDate();
      contact["source"] = this.source;
      contact["send_text_reminders"] = this.formData.send_text_reminders? this.formData.send_text_reminders : false;
      contact["active"] = true;
      if (this.appointment_details.subAccountRef) {
        contact['subAccountRefArray'] = [this.appointment_details.subAccountRef]
      }
      let newContactSnap = await this.acc_ref.collection("contacts").add(contact)
      return newContactSnap
    }
    else {
      let contactInfo = conatctQuerySnapshot.docs[0].data()
      if (this.formData.send_text_reminders) {
        contact["send_text_reminders"] = this.formData.send_text_reminders
      }
      contact["updated_on"] = moment().toDate();
      contact['subAccountRefArray'] = contactInfo.subAccountRefArray ? contactInfo.subAccountRefArray : []
      if (this.appointment_details.subAccountRef && !this.checkSubAccountExist(contact['subAccountRefArray'], this.appointment_details.subAccountRef)) {
        contact['subAccountRefArray'].push(this.appointment_details.subAccountRef)
      }
      await conatctQuerySnapshot.docs[0].ref.update(contact);
      return conatctQuerySnapshot.docs[0].ref;
    }
  }
  saveAppointment() {
    if (this.providersName === undefined && this.providerRef !== undefined) {
      this.getProvidersDetails(this.providerRef);
    }
    return this.saveContact().then((result) => {
      //console.log(result);

      this.bookedOn = moment();
      let appointment = {
        contact_info: {
          first_name: this.formData.first_name,
          last_name: this.formData.last_name,
          email: this.formData.email,
          phone: this.formData.phone_no,
        },
        contact: result,
        date: this.selectedDate.format("MMMM D, YYYY"),
        slot: `${this.selectedSlot.start} - ${this.selectedSlot.end}`,
        appt_start: moment
          .tz(
            `${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedSlot.start
            }`,
            "YYYY-MM-DD h:mm a",
            this.timezone
          )
          .toDate(),
        appt_end: moment
          .tz(
            `${this.selectedDate.format("YYYY-MM-DD")} ${this.selectedSlot.end
            }`,
            "YYYY-MM-DD h:mm a",
            this.timezone
          )
          .toDate(),
        booked_on: this.bookedOn.toDate(),
        source: this.source,
        status: "booked",
        services: this.formData.services,
        location: this.settings.location.name,
        notes: "",
        value: "",
        calendarReference: this.calendarRef,
        new_patient: this.formData.patientType,
        user_timezone: moment.tz.guess(),
        type: this.formData.type ? this.formData.type : '',
        isEmergency: this.formData.isEmergency ? this.formData.isEmergency : '',
        birthDate: this.formData.birthDate ? this.formData.birthDate : '',
        deviceInfo: this.deviceService.getDeviceInfo()
      };
      if (this.appointment_details.subAccountRef) {
        appointment['subAccountRef'] = this.appointment_details.subAccountRef
      }
      console.log(
        "appointment details from rescheduling appointment without contact info",
        appointment
      );
      if (this.providersName !== undefined || this.providerRef !== undefined) {
        appointment["providerReference"] = this.providerRef;
        appointment["provider_name"] = this.providersName;
      }
      console.log(this.formData);
      if (this.formData.street !== undefined) {
        this.address = {
          street: this.formData.street,
          city: this.formData.city,
          state: this.formData.state,
        };
        appointment["contact_info"]["address"] = this.address;
        console.log(appointment["contact_info"]["address"]);
      }
      if (this.formData.message !== undefined) {
        appointment["message"] = this.formData.message;
      }
      console.log(
        "appointment details from rescheduling appointment",
        appointment
      );
      console.log("contact info", appointment["contact_info"]);
      //return firebase.firestore().collection('appointments').add(appointment);
      //return firebase.firestore().collection('accounts').doc('7XZFnNuPDWuDqwOzc8cu').collection('appointments').add(appointment);
      return this.acc_ref.collection("appointments").add(appointment);
    });
  }

  saveAppointmentReference(reference: any) {
    if (this.multipleProviders == true) {
      this.url = `/reschedule-provider-groups/${this.acc_slug}/${this.provider_slug}`;
    } else if (this.providerLocations == true) {
      this.url = `/reschedule-provider-locations/${this.acc_slug}/${this.providerId}`;
    } else if (this.multipleLocations == true) {
      this.url = `/reschedule-calendar-groups/${this.acc_slug}/${this.group_slug}`;
    } else {
      this.url = `/reschedule-calendar/${this.acc_slug}/${this.calendar_slug}`;
    }
    let appointment_reference = {
      appt_ref: reference,
      redirectUrl: this.url,
    };
    return firebase
      .firestore()
      .collection("reschedules")
      .add(appointment_reference)
      .then((docId) => {
        this.rescheduleDoc = docId.id;
        console.log(this.rescheduleDoc);
      });
  }

  saveRescheduleDoc(appt_ref: any) {
    console.log("Reschedule doc added to appt", this.rescheduleDoc);
    appt_ref.update({ reschedule_doc: this.rescheduleDoc });
  }

  fetchAppointmentData(id: string) {
    return firebase
      .firestore()
      .collection("reschedules")
      .doc(id)
      .get()
      .then((reschedules_snapshot) => {
        let reschedules_doc = reschedules_snapshot.data();
        console.log(reschedules_doc);
        this.appt_ref = reschedules_doc.appt_ref;
        this.redirectUrl = reschedules_doc.redirectUrl;
        console.log(this.appt_ref);
        console.log(this.redirectUrl);
        //this.router.navigateByUrl(redirectUrl);

        return this.appt_ref.get().then((appointment) => {
          console.log(appointment.data());
          this.appointment_details = appointment.data();

          let contact_ref = this.appointment_details.contact;
          console.log(contact_ref);

          if (contact_ref)
            contact_ref.get().then((contact_details) => {
              this.contact_ref = contact_details.data();
              //  console.log(this.contact_ref);
            });
          this.oldProviderRef = this.appointment_details.providerReference;
          console.log("old ProfiderData: ", this.oldProviderRef);
          if (this.oldProviderRef !== undefined) {
            this.oldProviderRef.get().then((snap) => {
              let providerInfo = snap.data();
              this.oldProviderName = providerInfo.name;
              this.oldProviderEmail = providerInfo.email;
              console.log(this.oldProviderName, this.oldProviderEmail);
            });
          }
          return this.appointment_details;
        });
      });
  }

  getRedirectUrl() {
    return this.redirectUrl;
  }

  updateApptStatus() {
    console.log(this.appt_ref);
    return this.appt_ref.update({ status: "rescheduled" });
  }

  getContactDetails() {
    console.log(this.contact_ref);
    return this.contact_ref;
    /*
  return this.contact_ref.get()
                  .then(contact_details=>{
                      console.log(contact_details.data());
                    });*/
  }

  getOldAppointmentDate() {
    if (this.appointment_details.appt_start != undefined)
      return this.appointment_details.appt_start.toDate();
  }

  getOldAppointmentTime() {
    if (this.appointment_details.appt_start != undefined)
      return `${moment(this.appointment_details.appt_start.toDate())
        .tz(moment.tz.guess())
        .format("h:mm A")} - ${moment(this.appointment_details.appt_end.toDate())
          .tz(moment.tz.guess())
          .format("h:mm A")}`;
  }
  getOldLocation() {
    return this.appointment_details.location;
  }

  async statusToCancel(apptDetails) {
    this.acc_ref = this.appt_ref.parent.parent;
    let accSnapshot = await this.acc_ref.get()
    this.accDetails = await accSnapshot.data()
    this.acc_name = this.accDetails.name

    if (apptDetails.subAccountRef) {
      let subaccountSnapshot = await apptDetails.subAccountRef.get()
      this.accDetails.name = subaccountSnapshot.data().name
      this.acc_name = subaccountSnapshot.data().name
    }

    if (apptDetails.calendarReference) {
      let calendarSnapshot = await apptDetails.calendarReference.get()
      this.settings = calendarSnapshot.data()
    }

    await this.appt_ref.update({ status: "cancelled" });
    return true

  }

  async getProviderEmail(providerRef) {
    let getOldData = await providerRef.get(); //get old provider details
    let oldPrevData = getOldData.data();

    let providerEmail = oldPrevData.email;
    return providerEmail;
  }

  async rescheduleEmailToOwner() {
    this.formData["patientType"] =
      this.formData["patientType"] === "Yes" ? "New" : "Old";
    let to_email = this.settings.owner_email;
    console.log(this.oldProviderEmail, to_email);
    let allEmails = [];
    let appointmentInfo = {
      form_data: this.formData,
      old_date: `${moment(this.getOldAppointmentDate())
        .tz(this.timezone)
        .format("MMMM D, YYYY h:mm a z")}`,
      new_date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.selectedSlot.start} - ${this.selectedSlot.end} ${moment
        .tz(this.timezone)
        .format("z")}`,
      // "to_email": to_email,
      rescheduled_on: this.bookedOn
        .tz(this.timezone)
        .format("MMMM D, Y h:mm A z"),
      location: this.settings.location.name,
      links_msg: `<strong>Click here to </strong><a href="${environment.clientURL}/reschedule/${this.rescheduleDoc}">reschedule</a>
        <strong> or </strong><a href="${environment.clientURL}/cancel/${this.rescheduleDoc}">cancel</a><strong> this appointment.</strong>`,
    };
    if ('form_fields' in this.settings) {
      if (this.settings.form_fields.message.show === true) {
        appointmentInfo['title'] = this.settings.form_fields.message.title !== '' ? this.settings.form_fields.message.title : 'Message';
      }

    }
    if (this.oldProviderRef !== undefined) {
      if (this.oldProviderEmail !== to_email) {
        allEmails.push(this.oldProviderEmail);
        allEmails.push(this.currentPoviderEmail);
        console.log("old and new provider are different", allEmails);
        appointmentInfo["to_email"] = allEmails;
        appointmentInfo["oldProvider"] = this.oldProviderName;
      } else {
        appointmentInfo["to_email"] = to_email;
      }
    } else {
      appointmentInfo["to_email"] = to_email;
    }

    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }

    firebase
      .app("appt_firebase")
      .functions()
      .httpsCallable("rescheduleAppointmentEmailToOwner")(appointmentInfo)
      .then(
        (result) => {
          console.log(
            "Reschedule email sent to owner successfully!",
            appointmentInfo
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  rescheduleEmailToUser() {
    //  let reschedule_link = `<p>To reschedule the appointment, click here: <a href="http://localhost:4200/calendar/${this.acc_slug}/${this.calendar_slug}?appt_id = id">Reschedule</a></p>`
    let further_info = "";
    let appointment_with = this.acc_name;
    let message =
      this.settings.message === undefined ? "" : this.settings.message;
    if (this.settings.location.address === "") {
      further_info = `
          <p>
            ${message}
          </p>
          <p><strong>Click here to </strong><a href="${environment.clientURL}/reschedule/${this.rescheduleDoc}">reschedule</a>
          <strong> or </strong><a href="${environment.clientURL}/cancel/${this.rescheduleDoc}">cancel</a><strong> this appointment.</strong></p>
          `;
    } else {
      further_info = `
      <p>
        <strong>You can reach us at: </strong><br />
        ${this.settings.location.address}<br />
        <a href="${this.settings.location.map_link}" target="_blank">Get Directions</a><br />
        <strong>Contact No:</strong> <a href="tel:${this.settings.location.phone}">${this.settings.location.phone}</a>
        <br /><br />
        ${message}
      </p>
      <p><strong>Click here to </strong><a href="${environment.clientURL}/reschedule/${this.rescheduleDoc}">reschedule</a>
      <strong> or </strong><a href="${environment.clientURL}/cancel/${this.rescheduleDoc}">cancel</a> this appointment.</p>
      `;
    }
    //  console.log(further_info);
    /*
    let further_info = `
      <p>
        <strong>You can reach us at: </strong><br />
        1300 Rock Avenue, Ste. A4<br />
        North Plainfield, NJ 07060<br />
        [Across from Costco on Route 22 West]<br />
        <a href="https://www.google.co.in/maps/dir//Dental+Designer,+1300+Rock+Avenue+Suite+A4,+North+Plainfield,+NJ+07060,+USA/@40.6110347,-74.4984465,13z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89c3b9ae12acdcab:0x2440ac5c609afc78!2m2!1d-74.463427!2d40.61104?hl=en&authuser=0" target="_blank">Get Directions</a><br />
        <strong>Contact No:</strong> <a href="tel:9087566623">(908) 756-6623</a>
      </p>
    `;
    */
    let appointmentInfo = {
      appointment_with: appointment_with,
      old_date: `${moment(this.getOldAppointmentDate())
        .tz(moment.tz.guess())
        .format("MMMM D, YYYY h:mm a z")}`,
      new_date: this.selectedDate.format("MMMM D, YYYY"),
      slot: `${this.convertToLocalTime(
        this.selectedSlot.start
      )} - ${this.convertToLocalTime(
        this.selectedSlot.end
      )} ${this.getLocalTimezoneAbbr()}`,
      services: this.formData.services,
      to_email: this.formData.email,
      further_info: further_info,
    };
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }
    if (
      this.oldProviderName !== undefined &&
      this.providersName !== this.oldProviderName
    ) {
      appointmentInfo["oldProvider"] = this.oldProviderName;
    }
    firebase
      .app("appt_firebase")
      .functions()
      .httpsCallable("rescheduleAppointmentEmailToUser")(appointmentInfo)
      .then(
        (result) => {
          console.log(
            "Reschedule email sent to user successfully!",
            appointmentInfo
          );
        },
        (error) => {
          console.error(error);
        }
      );
  }

  cancelEmailToOwner(formData: any) {
    console.log("formdat", formData);
    delete formData["providerReference"];
    delete formData["subAccountRef"];
    let to_email = this.settings.owner_email;
    console.log("email of owner:", to_email);
    let appointmentInfo = {
      form_data: formData,
      date: moment(this.getOldAppointmentDate()).format("MMMM D, YYYY"),
      slot: `${moment(this.appointment_details.appt_start.toDate())
        .tz(this.settings.timezone)
        .format("h:mm a")} - ${moment(
          this.appointment_details.appt_end.toDate()
        )
          .tz(this.settings.timezone)
          .format("h:mm a")} ${moment.tz(this.settings.timezone).format("z")}`,
      to_email: to_email,
    };
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }


    firebase
      .app("appt_firebase")
      .functions()
      .httpsCallable("cancelAppointmentEmailToOwner")(appointmentInfo)
      .then(
        (result) => {
          console.log("Cancellation email sent to owner successfully!");
        },
        (error) => {
          console.error(error);
        }
      );
  }

  cancelEmailToUser(email: string) {
    let appointment_with = this.acc_name;
    let appointmentInfo = {
      appointment_with: this.acc_name,
      date: moment(this.getOldAppointmentDate()).format("MMMM D, YYYY"),
      slot: `${moment(this.appointment_details.appt_start.toDate())
        .tz(moment.tz.guess())
        .format("h:mm a")} - ${moment(
          this.appointment_details.appt_end.toDate()
        )
          .tz(moment.tz.guess())
          .format("h:mm a")} ${this.getLocalTimezoneAbbr()}`,
      to_email: email,
    };
    if (this.providersName !== undefined) {
      appointmentInfo["providerName"] = this.providersName;
    }
    firebase
      .app("appt_firebase")
      .functions()
      .httpsCallable("cancelAppointmentEmailToUser")(appointmentInfo)
      .then(
        (result) => {
          console.log("Cancellation email sent to user successfully!");
        },
        (error) => {
          console.error(error);
        }
      );
  }

  convertToLocalTime(timeString: string) {
    let tz_offset = moment().tz(this.timezone).format("Z");
    return moment(`${timeString} ${tz_offset}`, "h:mm a Z").format("h:mm A");
  }

  getLocalTimezoneAbbr() {
    return moment.tz(moment.tz.guess()).format("z");
  }
  //send appointment confirmation sms to user for sendAppointmentSms subscribed accounts only
  appointmentRescheduleSmsToUser(status) {
    if (this.accDetails.sendAppointmentSms !== undefined && this.accDetails.sendAppointmentSms == true) {
      let date = moment(this.appointment_details.appt_start.toDate()).format("MMMM D, YYYY");
      let time = `${moment(this.appointment_details.appt_start.toDate())
        .tz(moment.tz.guess())
        .format("h:mm a")} - ${moment(
          this.appointment_details.appt_end.toDate()
        ).tz(moment.tz.guess())
          .format("h:mm a")} ${this.getLocalTimezoneAbbr()}`;
      this.acc_ref.collection('linked_accounts').doc('Twilio_accounts').get().then(async snap => {
        let twilioDetails = snap.data();
        let msg = '';
        let newDate = this.selectedDate.format("MMMM D, YYYY");
        let slot = `${this.convertToLocalTime(this.selectedSlot.start)} - ${this.convertToLocalTime(this.selectedSlot.end)} ${this.getLocalTimezoneAbbr()}`
        msg = `${this.acc_name}: ${this.formData.first_name}, your appointment on ${date} from ${time} is ${status} to ${newDate} from ${slot}.\nLocation: ${this.settings.location.address}\nPhone: ${this.settings.location.phone}\nReply STOP to unsubscribe`;
        let targetLang = 'en';
        let snapshot = await this.acc_ref.collection('messages').doc(this.formData.countryCode + this.formData.phone_no).get();
        if (snapshot.exists) {
          let data = snapshot.data();
          targetLang = data ? data.targetLanguage : 'en';
        }
        let smsDetails = {
          to: [this.formData.countryCode + this.formData.phone_no],
          sourceMsg: msg,
          from: this.accDetails.twilio_from,
          acc_sid: twilioDetails.account_sid,
          auth_token: twilioDetails.auth_token,
          service_sid: twilioDetails.notify_service_sid,
          acc_id: this.acc_ref.id,
          contact_ids: [this.appointment_details.contact.id],
          sourceLanguage: 'en',
          targetLanguage: targetLang
        }
        console.log(smsDetails);
        // firebase.initializeApp(environment.mktg_firebase);
        this.http.post(`https://us-central1-${environment.mktg_firebase.projectId}.cloudfunctions.net/sendSmsV3`, smsDetails).subscribe(resp => {
          console.log(resp);
          console.log("sms Send to user");
        });
      });
    }
    else {
      console.log("not subscribed to send appointment sms");
    }
  }

  bookAnotherAppointment() {
    window.location.href = this.routerPaths[0].url
  }

}
