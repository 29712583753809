import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentService } from "../_services/appointment.service";
import { RescheduleAppointmentService } from "../_services/reschedule-appointment.service";

@Component({
  selector: "app-reschedule-appointment-status",
  templateUrl: "./reschedule-appointment-status.component.html",
  styleUrls: ["./reschedule-appointment-status.component.css"]
})
export class RescheduleAppointmentStatusComponent implements OnInit {
  status: string;
  errorIn: string;
  appointmentWith: string;
  selectedDate: any;
  selectedSlot: any;
  selectedServices: string;
  confirmationMsg: any;
  oldDate: any;
  oldProvider: string;
  providersName: string;
  location: string;
  
  constructor(
    private route: ActivatedRoute,
    public router: Router,
    public reschAppointmentService: RescheduleAppointmentService,
    public appointmentService: AppointmentService

  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe(queryParams => {
      this.status = queryParams.status;
      if (this.status === "rescheduled") {
        this.reschAppointmentService.apptConfirmed = true
        //this.appointmentWith = 'Dental Designer';
        this.appointmentWith = this.reschAppointmentService.acc_name;
        this.providersName = this.reschAppointmentService.providersName;
        console.log(this.providersName);
        this.location = this.reschAppointmentService.getSettings().location.name;

        let location = this.reschAppointmentService.getSettings().location;
        this.oldDate = this.reschAppointmentService.getOldAppointmentDate();
        console.log(this.oldDate);
        let message =
          this.reschAppointmentService.getSettings().message === undefined
            ? ""
            : this.reschAppointmentService.getSettings().message;
        if (location.address === "") {
          this.confirmationMsg = `
                          <p>${message}</p>`;
        }
        else {
          this.confirmationMsg = `
                                      <p><strong>You can reach us at</strong></p>
                                      <p>${location.address}</p>

                                      <p>Phone: <a href="tel:${location.phone}">${location.phone}</a></p>
                                      <u><p><a  href="${location.map_link}" target="_blank" >Get Directions</a></p></u><br />
                                      <p>${message}</p>`;
        }
        if (this.reschAppointmentService.oldProviderName !== this.reschAppointmentService.providersName) {
          this.oldProvider = this.reschAppointmentService.oldProviderName;
        }


        let date = this.reschAppointmentService.getSelectedDate();
        if (date !== undefined) {
          this.selectedDate = date.format("dddd, MMMM D YYYY");
          this.selectedSlot = this.reschAppointmentService.getSelectedSlot();
          this.selectedServices = this.reschAppointmentService.getSelectedServices();
        } else {
          if (this.reschAppointmentService.multipleLocations === true) {
            this.router.navigate(
              [
                "/reschedule-calendar",
                this.reschAppointmentService.acc_slug,
                this.reschAppointmentService.calendar_slug
              ],
              { queryParams: { multipleLocations: true } }
            );
          } else {
            this.router.navigateByUrl(
              `/reschedule-calendar/${this.reschAppointmentService.acc_slug}/${this.reschAppointmentService.calendar_slug}`
            );
          }
        }
      } else if (this.status === "unconfirmed") {
        this.errorIn = queryParams.errorIn;
      }
    });
  }

  goBack() {
    if (this.reschAppointmentService.multipleLocations === true) {
      this.router.navigate(
        [
          "/reschedule-calendar",
          this.reschAppointmentService.acc_slug,
          this.reschAppointmentService.calendar_slug
        ],
        { queryParams: { multipleLocations: true } }
      );
    } else {
      this.router.navigateByUrl(
        `/reschedule-calendar/${this.reschAppointmentService.acc_slug}/${this.reschAppointmentService.calendar_slug}`
      );
    }
  }
}
